import React, { useEffect, useState } from "react";
import { ActionModalProps } from "./domain/types";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { actionConfig } from "./config/actionConfigurations";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import FileUploader from "components/FileUploader/FileUploader";
import TagsFileUploader from "components/TagsFileUploader/FileUploader";
import { downloadTag, clearTag } from "api/tags";
import actionApi from "./domain/actionApi";
import Loader from "components/shared/Loader";
import ExpressionCalculator from "components/ExpressionCalculator/ExpressionCalculator";
import AssetsSelects from "components/Asset/AssetsSelects";
import ActionFormItem from "./ActionFormItem";
import FormSelects from "./FormSelects";
import { tagsState, expressionsState, assetsState, ticketsState } from "./config/stateConfig";

const ActionModal: React.FC<ActionModalProps> = ({ page, selectedTag, setSelectedTag, type, refresh, listing }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<any>({ type: 'none', message: '' });
  const [fileId, setFileId] = useState('');
  const [submitExp, setSubmitExp] = useState(false);
  const { value } = selectedTag;
  const { modalType } = actionConfig[page];
  const { title, formInputs, hasRanges, rangesInputs, submitBtn, submitAction, isFileUpload, hasSelects, selects } = actionConfig[page][type];
  const [equipmentId, setEquipmentId] = useState<number | null>(null);
  const [tag, setTag] = useState<any>(() => {
    if (page === 'tags') {
      return {
        ...tagsState,
        equipmentId: equipmentId,
      };
    } else if (page === 'expressions') {
      return {
        ...expressionsState
      };
    } else if (
      page === 'trains' ||
      page === 'equipments' ||
      page === 'units'
    ) {
      return {
        ...assetsState
      };
    }
    else if (
      page === 'tickets'
    ) {
      return {
        ...ticketsState,
        tagId: selectedTag.value?.id,
      };
    }
  });

  const [ancestors, setAncestors] = useState<any>(
    {
      trainId: null,
      unitId: null,
    }
  );

  const triggerRefresh = () => {
    setSelectedTag({ action: "", value: null });
    refresh();
  };

  const updateTag = (data: any) => {
    for (const key in tag) {
      if (data && Object.prototype.hasOwnProperty.call(data, key)) {
        setTag((prevState: any) => ({
          ...prevState,
          [key]: data[key]
        }));
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (page === 'tags') {
      const response = await actionApi({ tag: { ...value, ...tag }, api: submitAction });
      if (response?.message === 'success') {
        refresh();
        return setSelectedTag({ action: "", value: null });
      }
      else {
        setMessage({ type: 'error', message: response?.value });
      }
    }
    if (page === 'expressions') {
      setSubmitExp(true);
    }
    if (page === 'trains' || page === 'equipments' || page === 'units' || page === 'tickets') {
      const response = await actionApi({
        tag: {
          ...value, ...tag, ...ancestors
        },
        api: submitAction
      });
      if (response?.message === 'success') {
        refresh();
        return setSelectedTag({ action: "", value: null });
      }
      else {
        setMessage({ type: 'error', message: response?.value });
      }
    }
    if (page === 'docs') {
      if (!listing) {
        setIsLoading(false);
        return setSelectedTag({ action: "", value: null });
      }
      const response = await actionApi({
        tag: {
          trainId: value.selectedTrainId,
          unitId: value.selectedUnitId,
          equipmentId: value.selectedEquipmentId,
          fileId: fileId
        },
        api: submitAction
      });
      if (response?.message === 'success') {
        refresh();
        return setSelectedTag({ action: "", value: null });
      } else {
        setMessage({ type: 'error', message: response?.value });
      }
    }
    setIsLoading(false);
  };

  const resetValues = () => {
    setTag((prev: any) => ({
      ...prev,
      minValue: 0,
      maxValue: 0,
      minRange: 0,
      maxRange: 0
    }));
  };


  const downloadFile = async () => {
    setMessage({ type: 'success', message: 'Your download is in progress. Thank you for your patience' });
    const response = await downloadTag(selectedTag?.value?.id);
    if (response.status === 200) {
      const result = response.data;
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${selectedTag?.value?.tagNameIdentifier}.csv`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setMessage({ type: 'success', message: 'File has been downloaded successfully' });
    }
    else {
      setMessage({ type: 'error', message: 'Something went wrong' });
    }
  };

  useEffect(() => {
    updateTag(value);
  }, [selectedTag]);

  useEffect(() => {
    if (page === 'trains') {
      setAncestors({
        trainId: null,
        unitId: null,
      });
    }
    if (page === 'units') {
      setAncestors({
        trainId: listing?.selectedTrainId,
        unitId: null,
      });
    }
    if (page === 'equipments') {
      setAncestors({
        trainId: listing?.selectedTrainId,
        unitId: listing?.selectedUnitId,
      });
    }
  }, [listing]);

  useEffect(() => {
    if (listing) {
      setTag((prev: any) => ({ ...prev, equipmentId: listing.selectedEquipmentId }));
    }
  }, [listing]);

  return (
    <>
      {!isLoading && (
        <div className="z-10 h-full max-h-[87vh] w-full">
          <BorderGradient className="w-full h-max">
            <div className="w-full h-max p-3 bg-dark rounded-md">
              <div className="flex flex-wrap justify-between pb-2 2xl:pb-5">
                <h2 className="text-xl font-bold">{title}</h2>
                <button
                  className="cursor-pointer bg-green rounded-md p-1"
                  onClick={() =>
                    setSelectedTag({ action: "", value: null })
                  }
                >
                  <FiX size={20} />
                </button>
              </div>

              {message.type !== 'none' && <div className={classNames(message.type === 'error' ? "text-red" : "text-green", "text-xl text-center pb-2")}>{message.message}</div>}

              <div
                className={classNames("max-h-[80vh] xl:max-h-[73vh] overflow-hidden overflow-y-scroll pb-3"
                )}
              >
                <form className="grid gap-2 py-1 2xl:py-4" >
                  {formInputs.map((singleInput, index) => {
                    return (<ActionFormItem key={index} item={singleInput} tag={tag} setTag={setTag} />);
                  })}
                  {page === 'expressions' && <ExpressionCalculator actionType={type} expId={selectedTag?.value?.id} isLoading={isLoading} setIsLoading={setIsLoading} expInfo={tag} isSubmit={submitExp} setSubmit={setSubmitExp} setModalMessage={setMessage} triggerRefresh={triggerRefresh} />}
                  {modalType === 'complex' && <div className="flex gap-4 justify-center items-center">
                    <div className="flex flex-row flex-wrap gap-3 justify-between">
                      {hasRanges &&
                        rangesInputs.map((singleInput, index) => {
                          return (<ActionFormItem key={index} item={singleInput} tag={tag} setTag={setTag} />);
                        })
                      }
                    </div>
                    <button
                      className="btn h-max px-2 mt-7"
                      type="button"
                      onClick={resetValues}
                    >
                      Reset
                    </button>
                  </div>}
                  {page === 'tickets' && (
                    <FormSelects selects={selects} tag={tag} setTag={setTag} />
                  )}
                </form>
                {isFileUpload && page==="docs" && <FileUploader fileType="asset_doc" setMessage={setMessage} setId={setFileId} />}
                {isFileUpload && page!=='docs' && <TagsFileUploader setMessage={setMessage} />}
                {ancestors && <AssetsSelects ancestors={ancestors} setAncestors={setAncestors} page={page} type={type} />}
                <div className="flex justify-end">
                  {type === 'edit' && modalType === 'complex' && <>
                    <div className="flex items-start gap-3 flex-grow">
                      <button className="btn" onClick={downloadFile}>
                        Download Data
                      </button>
                      <button className="btn" onClick={clearTag}>
                        Clean Data
                      </button>
                    </div>
                  </>}
                  <button className="btn" type="submit" onClick={handleSubmit}>{submitBtn}</button>
                </div>
              </div>
            </div>
          </BorderGradient>
        </div>
      )}
      {isLoading &&
        <div className="grid place-content-center bg-b relative z-30">
          <Loader />
        </div >
      }
    </>
  );
};

export default ActionModal;