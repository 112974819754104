export const dashboardconfigmenu = [
  {
    name: "TAGS",
    to: "/dashboard-configuration/tags",
    route: "tags"
  },
  {
    name: "EXPRESSIONS",
    to: "/dashboard-configuration/expressions",
    route: "expressions"
  },
  {
    name: "DOCUMENTS",
    to: "/dashboard-configuration/documents",
    route: "documents"
  },
  {
    name: "ASSETS (TRAIN/UNIT/EQUIPMENT)",
    to: "/dashboard-configuration/all-assets",
    route: "all-assets"

  }
];