
import { useEffect, useState, useContext } from "react";
import { FiArrowLeft, FiMenu, FiSettings } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import SideMenu from "../Sidemenu/SideMenu";
import UserMenu from "../UserMenu";
import classNames from "utilities/ClassNames";
import setBodyColor from "test";
import ProgressMonitoringLogo from "components/shared/ProgressMonitoringLogo";
import useScreen from "hooks/useScreen";
import DateTime from "../DateTime";
import Info from "../Info";
import { TfiAgenda } from "react-icons/tfi";
import { ReactComponent as GanttChart } from "assets/ganttChart.svg";
import { ReactComponent as TimelineLogo } from "assets/timeline.svg";
import { ReactComponent as MapIcon } from "assets/mapIcon.svg";
import { ModalContext } from "hooks/ModalProvider";
import HeaderMenu from "./HeaderMenu";
import useUnity from "hooks/UseUnity";

export default function Header() {
  const { modalType, setModalType } = useContext(ModalContext);
  const { pathname } = useLocation();
  const [showSideMenu, setShowMenu] = useState(false);

  const [ganttShown, setGanttShown] = useState(false);
  const [reportShown, setReportShown] = useState(false);
  const [settingsShown, setSettingsShown] = useState(false);
  const [mapShown, setMapShown] = useState(false);
  const linkTarget = "_self";
  const { isScaled } = useScreen();
  const { TimelineShown, setTimelineShown } = useUnity();
  const menus = [
    {
      key: "3dplant",
      name: "3D Plant",
      path: "/3d-dashboard",
      islink: true,
      target: linkTarget,
      adminView: false,
    },
    {
      key: "dashboard",
      name: "Dashboard",
      path: "/dashboard",
      islink: true,
      target: linkTarget,
      adminView: false,
    },
  ];

  useEffect(() => {
    if (modalType === 'report') {
      setReportShown(true);
      setSettingsShown(false);
      setGanttShown(false);
      setMapShown(false);
    }
    if (modalType === 'settings') {
      setSettingsShown(true);
      setReportShown(false);
      setGanttShown(false);
      setMapShown(false);
    }
    if (modalType === 'gantt') {
      setGanttShown(true);
      setReportShown(false);
      setSettingsShown(false);
      setMapShown(false);
    }
    if (modalType === 'map') {
      setMapShown(true);
      setReportShown(false);
      setSettingsShown(false);
      setGanttShown(false);
    }
    if (modalType === '') {
      setReportShown(false);
      setSettingsShown(false);
      setGanttShown(false);
      setMapShown(false);
    }
  }, [modalType]);

  if (window.vuplex && pathname === "/3d-dashboard") {
    setBodyColor({ color: "rgb(0,0,0,0)" });
  } else {
    setBodyColor({ color: "rgb(57,57,57,1)" });
  }

  const buttonsConfig = [
    {
      name: 'Timeline',
      shown: TimelineShown,
      type: 'component',
      onClick: () => setTimelineShown((prev:any) => !prev),
      Icon: TimelineLogo,
      activeClass: "headerIconActive",
    },
    {
      name: 'Gantt Chart',
      shown: ganttShown,
      type: 'component',
      onClick: () => setModalType('gantt'),
      Icon: GanttChart,
      activeClass: "headerIconActive",
    },
    {
      name: 'Report',
      shown: reportShown,
      type: 'icon',
      onClick: () => setModalType('report'),
      Icon: TfiAgenda,
      activeClass: "text-blue",
    },
    {
      name: 'Map',
      shown: mapShown,
      type: 'component',
      onClick: () => setModalType('map'),
      Icon: MapIcon,
      activeClass: "headerIconActive",
    },
    {
      name: 'Settings',
      shown: settingsShown,
      type: 'icon',
      onClick: () => setModalType('settings'),
      Icon: FiSettings,
      activeClass: "text-blue",
    },
  ];

  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          "lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}
      >
        <div className={classNames(isScaled ? 'gap-3' : 'gap-2', "relative flex items-center w-full")}>
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300 z-50 "
          >
            {!showSideMenu && <FiMenu size={"40"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            {showSideMenu && <FiArrowLeft size={"40"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
          </div>
          <Link to="/" className="z-50">
            <ProgressMonitoringLogo variant="regular" />
          </Link>
          <div className="w-full">
            <div className="flex ml-4 gap-4 w-full">
              {menus.map((page) => {
                return (
                  <div key={page.name}>
                    <Link target={page.target} to={page.path}>
                      <>
                        <div className={classNames("lg:h-[2.15rem] 2xl:h-[2.65rem] w-full", "rounded-lg bg-gradient-to-r from-green to-blue  items-center justify-center")}>
                          <button className={classNames("h-[2.40rem] w-40 text-xl lg:h-[1.95rem] 2xl:h-[2.40rem] lg:w-32 2xl:w-40 lg:text-sm 2xl:text-lg", "text-center bg-gradient-to-r from-gray to-black rounded-lg hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 ")}>
                            {page.name}
                          </button>
                        </div>
                      </>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <HeaderMenu buttonsConfig={buttonsConfig} />
        <DateTime />
        <Info />
        <UserMenu />
        <SideMenu
          closeMenu={() => setShowMenu(false)}
          showSideMenu={showSideMenu}
        />
      </div>
    </div >
  );
}
