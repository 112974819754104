import React, { useEffect, useState } from "react";
import Gauge from "components/charts/Gauge/Gauge";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import { cn } from "@/lib/utils";
import useData from "hooks/useData";
import EmptyComponent from "components/shared/EmptyComponent/EmptyComponent";

const GaugeBox = React.memo(({ data, name }: any) => {
  return (
    data && (
      <div className={cn("p-1 h-[95%]", "rounded-md bg-dark flex items-center justify-center")}>
        <Gauge data={data} name={name} gaugeType={"regular"} />
      </div>
    )
  );
});

GaugeBox.displayName = 'GaugeBox';

const GaugePage = ({ tab }: any) => {
  const { pastDate } = useData();
  const [loading, setLoading] = useState(true);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [nonNullData, setNonNullData] = useState<any>([]);
  const data = tab.configurations;
  const namesCollection = Object.entries(data).map((singleItem: any) => singleItem[1]?.name);

  useEffect(() => {
    setNonNullData(processedData.filter((item: any) => item !== null));
  }, [processedData]);

  useEffect(() => {
    const fetchData = async () => {
      const dataArray = Object.entries(data).map((item) => item[1]);
      const promises = dataArray.map(async (single: any) => {
        if (single.id !== '') {
          const response = single?.type === 'tags' ? await getTagData([single?.id], pastDate) : await getExpressionData([single?.id], pastDate);
          if (response.status === 200) {
            return response.data[0];
          }
        }
        return null;
      });
      const results = await Promise.all(promises);
      setLoading(false);
      setProcessedData(results);
    };

    fetchData();
    const intervalId = setInterval(fetchData, 15000);
    return () => clearInterval(intervalId);
  }, [data, pastDate]);
  return (
    <div className="h-full">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <span>Loading...</span> {/* Add a loading spinner here if desired */}
        </div>
      ) : (
        <div className="grid grid-cols-2 grid-rows-2 gap-3 h-full">
          {nonNullData.length > 0 ? (
            processedData.map((single, index) => (
              <GaugeBox key={index} data={single} name={namesCollection[index] || single?.name || "Unknown"} />
            ))
          ) : (
            <EmptyComponent />
          )}
        </div>
      )}
    </div>
  );
};

export default GaugePage;
