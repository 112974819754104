import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useApp from "hooks/useApp";
import { useState } from "react";
import { FiChevronDown, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Profile from "../profile/profile";
import { useAuth } from "react-oidc-context";

export default function UserMenu() {
  const navigate = useNavigate();
  const { user } = useApp();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     const response: any = await getUser();
  //     if (response?.status === 200) {
  //       setUser(response.data);
  //     }
  //   })();
  // }, []);

  const auth = useAuth();
  const logout = async () => auth.signoutRedirect();

  // debugger;// eslint-disable-line no-use-before-define

  // console.error(auth);

  const healthsensor = () => {
    navigate("/health");
  };

  return (
    <>
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center cursor-pointer">
              {!user?.avatar && (
                <div className="gradient p-1 rounded-full">
                  <div className="w-7 h-7 grid place-content-center bg-dark rounded-full">
                    <FiUser size={20} />
                  </div>
                </div>
              )}
              {user?.avatar && (
                <div className="gradient p-1 rounded-full">
                  <Avatar className="w-7 h-7">
                    <AvatarImage
                      src={`data:image/jpeg;base64,${user?.avatar}`}
                      alt="@shadcn"
                    />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                </div>
              )}
              <div className="flex items-center gap-2 font-bold whitespace-nowrap">
                <span className="capitalize">{user?.firstName}</span>
                <span className="capitalize">{user?.lastName}</span>
              </div>
              <div className="mt-1">
                <FiChevronDown />
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48 bg-dark mt-3 border-slate">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="cursor-pointer hover:bg-slate"
                onClick={() => setShowProfileMenu(true)}
              >
                Profile
              </DropdownMenuItem>
              {/* <DropdownMenuItem
                className="cursor-pointer hover:bg-slate"
                onClick={healthsensor}
              >
                Health Sensor
              </DropdownMenuItem> */}
              <DropdownMenuItem
                onClick={logout}
                className="cursor-pointer hover:bg-slate"
              >
                Logout
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Profile
          closeMenu={() => setShowProfileMenu(false)}
          showProfileMenu={showProfileMenu}
        />
      </>
    </>
  );
}

// import avatar from "assets/avatar.png";
// import { motion } from "framer-motion";
// import useApp from "hooks/useApp";
// import { useState } from "react";
// import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

// export default function UserMenu() {
// 	const navigate = useNavigate();
// 	const { setAuthStatus } = useApp();
// 	const [openUserMenu, setOpenUserMenu] = useState(false);

// 	const slide = {
// 		enter: {
// 			opacity: 1,
// 			y: 0,
// 			transition: {
// 				duration: 0.2,
// 			},
// 			display: "flex",
// 		},
// 		exit: {
// 			opacity: 0,
// 			y: -10,
// 			transition: {
// 				duration: 0.1,
// 			},
// 			transitionEnd: {
// 				display: "none",
// 			},
// 		},
// 	};

// 	const logout = () => {
// 		setAuthStatus("idle");
// 		localStorage.removeItem("token");
// 		navigate("/login");
// 	};

// 	return (
// 		<div className="relative">
// 			<div className="cursor-pointer" onClick={() => setOpenUserMenu(prev => !prev)}>
// 				<img
// 					className="h-7 w-7 rounded-full object-contain"
// 					src={avatar}
// 					crossOrigin="anonymous"
// 					alt="avatar"
// 				/>
// 			</div>
// 			{openUserMenu && (
// 				<div
// 					className="fixed inset-0 z-10 cursor-default bg-transparent"
// 					onClick={() => setOpenUserMenu(false)}
// 				></div>
// 			)}
// 			<motion.div
// 				initial="exit"
// 				animate={openUserMenu ? "enter" : "exit"}
// 				variants={slide}
// 				className="absolute right-0 top-10 z-20 rounded-md border bg-white shadow-sm dark:border-stone-800 dark:bg-dark"
// 			>
// 				<div className="left- flex w-40 flex-col">
// 					<div className="px-1 py-1">
// 						<div className="group flex w-full cursor-default items-center justify-between rounded-md px-2 py-2">
// 							Dark Mode
// 						</div>
// 					</div>
// 					<div className="px-1 py-1">
// 						<div
// 							className="cursor-pointer rounded-md px-2 py-2 duration-300 hover:bg-stone-100 dark:hover:bg-black"
// 							onClick={logout}
// 						>
// 							Logout
// 						</div>
// 					</div>
// 				</div>
// 			</motion.div>
// 		</div>
// 	);
// }
