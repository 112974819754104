function splitFilesIntoChunks(files: any[], chunkSize: number) {
  const keys: any = Object.keys(files);
  const result = [];

  for (let i = 0; i < keys.length; i += chunkSize) {
    const chunk: any = {};
    for (let j = i; j < i + chunkSize && j < keys.length; j++) {
      chunk[keys[j]] = files[keys[j]];
    }
    result.push(chunk);
  }

  return result;
}

export default splitFilesIntoChunks;