import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header/Header";

export default function Layout() {
  const location = useLocation();
  const is3DDashboard = location.pathname === '/3d-dashboard';

  return (
    <div className={`relative overflow-hidden w-full h-full 2xl:pb-0 max-w-[2800px] mx-auto ${!is3DDashboard ? 'bg-black' : ''}`}>
      <Header />
      <Outlet />
    </div>
  );
}
