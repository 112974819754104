import { ReactComponent as ProcessHubLogoBlue } from "assets/logoBlue.svg";
import { ReactComponent as ProcessHubLogoBlack } from "assets/logoBlack.svg";
import classNames from "utilities/ClassNames";

const ProgressMonitoringLogo = ({ variant }: { variant: string }): JSX.Element => {

  return (
    <div className="flex justify-center items-center" >
      {variant === 'regular' && <ProcessHubLogoBlue className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")} />}
      {variant === 'large' && <ProcessHubLogoBlack className={classNames("lg:h-18 2xl:h-20")} />}
      <span className={classNames(variant === 'large' ? "text-black text-8xl" : "lg:text-md 2xl:text-2xl", "font-bold ml-1")}>Progress</span>
      <span className={classNames(variant === 'large' ? "text-black text-8xl" : "lg:text-md 2xl:text-2xl", "ml-1")}>Monitoring</span>
    </div >
  );
};

export default ProgressMonitoringLogo;
