import { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { FiX } from "react-icons/fi";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import Loader from "components/shared/Loader";
import styles from "./config/mapStyles.json";
import { useCoordinates } from "hooks/useCoordinates";

const MapModal = ({ setSelectedTag, setModalType }: any) => {
  const { coordinates, setCoordinates } = useCoordinates();
  const [isMapsReady, setIsMapsReady] = useState(false);

  useEffect(() => {
    if (coordinates.lat === 0 || coordinates.lng === 0) {
      setCoordinates({ lat: 24.740593, lng: 46.849801 });
    }
  }, [coordinates, setCoordinates]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCaAnlPllExl2Yuk5G6RENFb0zlBQUXFWY",
  });

  useEffect(() => {
    setIsMapsReady(isLoaded);
  }, [isLoaded]);

  useEffect(() => {
    if (isMapsReady && coordinates) {
      const mapElement = document.getElementById("map");
      if (mapElement) {
        const map = new window.google.maps.Map(mapElement, {
          center: coordinates,
          zoom: 6.5,
          styles: styles,
          zoomControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          minZoom: 3,
          maxZoom: 100,
        });

        new window.google.maps.Marker({
          position: coordinates,
          map: map,
        });
      }
    }
  }, [isMapsReady, coordinates]);

  return isMapsReady ? (
    <div className="z-10 h-full max-h-[87vh] w-full opacity-95">
      <BorderGradient className="w-full h-max">
        <div className="w-full h-max p-3 bg-dark rounded-md">
          <div className="flex justify-between">
            <h3 className="text-2xl">Location</h3>
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() => {
                setSelectedTag({ action: "", value: null });
                setModalType('');
              }}
            >
              <FiX size={20} />
            </button>
          </div>
          <div className="google-map h-[70vh] p-2">
            <BorderGradient className="w-full h-full">
              <div id="map" className="h-full w-full rounded-md"></div>
            </BorderGradient>
          </div>
        </div>
      </BorderGradient>
    </div>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <Loader />
    </div>
  );
};

export default MapModal;
