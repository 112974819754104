

export default (data: any) => {
  return data.map((single: any) => {
    return {
      ...single,
      createdOnUTC: new Date(single.createdOnUTC).toISOString().split('T')[0],
      size: single.size > 999 ? `${(single.size / 1000).toFixed(1)} KB` : `${single.size} B`,
    };
  });
};