import { useEffect, useState } from "react";
import EmptyHeader from "components/layout/emptyHeader";
import { Link } from "react-router-dom";
import classNames from "utilities/ClassNames";

const LoginErrorPage = () => {

  const isScaled = window.devicePixelRatio > 1;
  const [isVuplex, setIsVuplex] = useState(false);

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }

  useEffect(() => {
    if (window.vuplex && location.pathname === '/3d-dashboard') {
      sendCanvasVisibility(0);
      return () => {
          sendCanvasVisibility(1);
      };
    }
    else{
      return () => {
        sendCanvasVisibility(0);
      };
    }
  }, [window.vuplex,location.pathname]);




  return (
    <div className="max-h-screen w-full overflow-hidden bg-transparent">
      <EmptyHeader />
      <div className={classNames(isVuplex ? "left-5 top-20 items-start justify-start w-max" : "left-0 top-0 items-center justify-center w-full h-full outline", "absolute flex flex-wrap flex-col")}>
        <span className={classNames(isScaled ? "text-2xl mt-4" : "text-xl", "font-bold w-max")}>You are offline, try reloading the page</span>
        <div className={classNames(isScaled ? "h-[3.25rem] w-max text-2xl" : "h-[2.65rem]", "mt-4 rounded-lg bg-gradient-to-r from-green to-blue w-max items-center justify-center z-50")}>
          <Link to="/">
            <button className={classNames(isScaled ? "h-[3rem] w-max px-12" : "h-[2.40rem]", "cursor-pointer bg-gradient-to-r from-gray to-black rounded-lg w-40  hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 ")}>
              Reload Page
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginErrorPage;