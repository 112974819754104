import { Link } from "react-router-dom";
import classNames from "utilities/ClassNames";
import loginBackground from "assets/loginBackground.jpg";
export default function PageNotFound({type}:any) {
    // const { isScaled } = useScreen();
    const isScaled = false;
    // let tjson: any = 'test';
    // function addMessageListener() {
    //   window.vuplex.addEventListener("message", function (event: { data: any }) {
    //     tjson = event.data;
    //     // > JSON received: { "type": "greeting", "message": "Hello from C#!" }
    //   });
    // }
    // if (window.vuplex) {
    //   addMessageListener();
  
    // } else {
    //   window.addEventListener('someTestMethod', addMessageListener);
    // }

    const errorTypes:any = [
        {
            name:'not found',
            phrase1:'404 - Page Not Found!',
            phrase2:'The page you are looking for does not exist.'

        },
        {
            name:'undev',
            phrase1:'Page Under Development.',
            phrase2:'Our page is currently under construction. We apologize for any inconvenience and appreciate your patience.'
        }
    ];

    return (
      <div>
        <div className="absolute left-0 right-0 top-5 -bottom-10">
          <img src={loginBackground} className="w-full h-full"></img>
          <div className="left-0 top-[42%] absolute w-full">
            <div className="h-max w-max mx-auto">
              {/* <ProgressMonitoringLogo variant="large" /> */}
              <span className={classNames(isScaled ? "text-4xl mt-2" : "text-7xl mt-1", "text-black opacity-75 font-bold block text-center")}>{errorTypes[type].phrase1}</span>
              <span className={classNames(isScaled ? "text-4xl mt-2" : "text-2xl mt-1", "text-black opacity-75 font-bold block text-center")}>{errorTypes[type].phrase2}</span>
            </div>
          </div>
        </div>
        <div className={classNames(isScaled ? "w-60 bottom-10 left-6" : "w-40 bottom-0 left-4", "absolute h-20")}>
          {/* <img src={logo}></img> */}
        </div>
        <div className={classNames(isScaled ? "text-2xl mb-4" : "", "absolute bottom-0 left-[45%] flex mb-2")}>
          <Link to={"https://processhub.ai/"} style={{ color: 'darkblue', textDecoration: 'underline' }}>www.processhub.ai</Link>
        </div>
      </div>
    );
  }