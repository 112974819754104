import useUnity from "hooks/UseUnity";
import classNames from "utilities/ClassNames";

const TabsMenuItem = ({ isSelected, name, onClickHandler }: any) => {
  return (
    <div
      className={classNames(
        isSelected ? "gradient" : "bg-dark",
        "pb-1"
      )}
    >
      <div
        onClick={onClickHandler}
        className="cursor-pointer font-bold w-full h-full bg-dark pb-1"
      >
        {name}
      </div>
    </div>
  );
};

const TabsMenu = ({ tabs }: any) => {
  const {IsBubbleChart} = useUnity();
  return (
    <div className={classNames("flex gap-5",window.location.pathname==="/3d-dashboard" && IsBubbleChart?"-mt-5":"pl-3 pt-5 pb-5")}>
      {tabs.map((single: any, key: number) => {
        return <TabsMenuItem key={key} isSelected={single.isSelected} name={single.name} onClickHandler={single.onClickHandler} />;
      })}
    </div>
  );
};

export default TabsMenu;