import React, { useContext, useState } from 'react';

// Define the shape of our context
type CoordinatesState = {
  coordinates: { lat: number; lng: number };
  setCoordinates: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>;
};

// Create a context with a default value
const CoordinatesContext = React.createContext<CoordinatesState | undefined>(undefined);

// Create a provider component
export const CoordinatesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  return (
    <CoordinatesContext.Provider value={{ coordinates, setCoordinates }}>
      {children}
    </CoordinatesContext.Provider>
  );
};

// Create a custom hook to use the coordinates context
export const useCoordinates = () => {
  const context = useContext(CoordinatesContext);
  if (!context) {
    throw new Error('useCoordinates must be used within a CoordinatesProvider');
  }
  return context;
};
