import { createContext, useContext, useState } from "react";

export interface pointCloud {
  name: string;
  id: string;
  scannedAt: string;
  attribute: string;
}

export const UnityContext = createContext<any | null>(null);

export const UnityProvider = ({ children }: { children: any }) => {
  const [projectStartdate, setprojectStartdate] = useState<Date>(
    new Date("03-19-2024")
  );
  const [TimelineShown, setTimelineShown] = useState<boolean>(false);
  const [timelineLoading, setTimlineLoading] = useState(false);
  const [projectEnddate, setprojectEnddate] = useState<Date>(new Date());
  const [selectedValues, setSelectedValues] = useState<pointCloud[] | null>(
    null
  );

  const [IsBubbleChart, setIsBubbleChart] = useState<boolean>(false);

  return (
    <UnityContext.Provider
      value={{
        projectStartdate,
        setprojectStartdate,
        projectEnddate,
        setprojectEnddate,
        selectedValues,
        setSelectedValues,
        TimelineShown,
        setTimelineShown,
        timelineLoading,
        setTimlineLoading,
        IsBubbleChart,
        setIsBubbleChart,
      }}
    >
      {children}
    </UnityContext.Provider>
  );
};

export default function useUnity() {
  return useContext(UnityContext);
}
