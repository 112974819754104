import { FaRegFileAlt, FaRegFolderOpen } from 'react-icons/fa';
import React from 'react';

interface FileData {
  created: string | number | Date;
  modified: string | number | Date;
  type: number;
  icon?: React.ReactElement;
  [key: string]: any;
}

const fileDataProcessor = (fileData: FileData[]): FileData[] => {
  return fileData.map((item) => {
    const processedItem: FileData = {
      ...item,
      size: item.size > 999 ? `${(item.size / 1000).toFixed(1)} KB` : `${item.size} B`,
      created: new Date(item.created).toISOString().split('T')[0],
      modified: new Date(item.modified).toISOString().split('T')[0],
    };

    if (item.type === 1) {
      processedItem.icon = <FaRegFileAlt />;
    }
    if (item.type === 0) {
      processedItem.icon = <FaRegFolderOpen />;
    }

    return processedItem;
  });
};

export default fileDataProcessor;

