import logoImg from "assets/logoBlue.svg";
import Loader from "components/shared/Loader";

interface SplashScreenInterface {
  open: boolean;
  logo?: boolean;
  flicker1?: string;
  flicker2?: string;
  status?: string;
}

export const SplashScreen: React.FunctionComponent<SplashScreenInterface> = ({
  open = false,
  logo = false,
  flicker1 = "LOAD",
  flicker2 = "ING",
  status,
}) => {
  if (open) {
    return (
      <>
        <div className="absolute grid place-content-center h-full w-full bg-black" style={{visibility:window.vuplex?'hidden':'visible'}}>
          <div className="flex flex-col items-center gap-5">
            <img src={logoImg} alt="Processhub" className="logo-medium" />
            <Loader />
            <div>{status}</div>
          </div>
        </div>
        {/* <div className="loader-container">
          <div className="loader-content">
            {logo && (
              <img src={logoImg} alt="Processhub" className="logo-medium" />
            )}
            <div className="loader">
              <span
                data-flicker-0={flicker1}
                data-flicker-1={flicker2}
                className="loader-text"
              >
                {flicker1 + flicker2}
              </span>
            </div>
            
          </div>
          <div className="loader-status">{status}</div>
        </div> */}
      </>
    );
  } else {
    return <></>;
  }
};
