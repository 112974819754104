export type ColorOption = {
  color: string;
  unlabelled: string;
  labelled: string;
};

export type ColorOptions = {
  red: ColorOption;
  blue: ColorOption;
  green: ColorOption;
  orange: ColorOption;
  purple: ColorOption;
  yellow: ColorOption;
  pink: ColorOption;
  cyan: ColorOption;
  brown: ColorOption;
  grey: ColorOption;
};

export const colorOptions: any = {
  red: {
    color: '#BC0000',
    unlabelled: 'pointCloudMarkRed',
    labelled: 'pointCloudLabelledMarkRed',
  },
  blue: {
    color: '#00568E',
    unlabelled: 'pointCloudMarkBlue',
    labelled: 'pointCloudLabelledMarkBlue',
  },
  green: {
    color: '#048E00',
    unlabelled: 'pointCloudMarkGreen',
    labelled: 'pointCloudLabelledMarkGreen',
  },
  orange: {
    color: '#FF9E00',
    unlabelled: 'pointCloudMarkOrange',
    labelled: 'pointCloudLabelledMarkOrange',
  },
  purple: {
    color: '#9B00FF',
    unlabelled: 'pointCloudMarkPurple',
    labelled: 'pointCloudLabelledMarkPurple',
  },
  yellow: {
    color: '#FFD300',
    unlabelled: 'pointCloudMarkYellow',
    labelled: 'pointCloudLabelledMarkYellow',
  },
  pink: {
    color: '#FF007F',
    unlabelled: 'pointCloudMarkPink',
    labelled: 'pointCloudLabelledMarkPink',
  },
  cyan: {
    color: '#00FFFF',
    unlabelled: 'pointCloudMarkCyan',
    labelled: 'pointCloudLabelledMarkCyan',
  },
  brown: {
    color: '#8B4513',
    unlabelled: 'pointCloudMarkBrown',
    labelled: 'pointCloudLabelledMarkBrown',
  },
  grey: {
    color: '#808080',
    unlabelled: 'pointCloudMarkGrey',
    labelled: 'pointCloudLabelledMarkGrey',
  },
};

