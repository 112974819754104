import Slider from '@mui/material/Slider';
import { styled } from '@mui/system';

export const PrettoSlider = styled(Slider)({
    borderRadius: '3px',
    // borderRadius:'2px',
    '& .MuiSlider-thumb': {
      visibility:'hidden',
    },
    '& .MuiSlider-mark': {
      height: 0,
      width: 2,
      backgroundColor: '#FFFFFF',
      marginTop: '48px',
      '&:nth-child(15n+3)': {
        height: 10,   
        marginTop: '48px',
      },
      '&:nth-child(3)': {
        height: 25,   
        marginTop: '35px',
      },
      '&:nth-last-child(2)': {
        height: 25,   
        marginTop: '35px',
      },

    },
    '& .MuiSlider-track': {
      backgroundColor: 'white',
      opacity: 0,
      width: 1150,
      marginTop: '55px'
    },
    '& .MuiSlider-rail': {
      width: '100.15%',
      backgroundColor: 'white',
      opacity: 0.6,
      height: 2,
      marginLeft: '-1px',
      marginTop: '48px',
    },
  });
  