import React, { useEffect, useState } from "react";
import { FileUploaderProps } from "./domain/types";
import { uploadFile, uploadImagesForPointClouds } from "api/fileManager";
import { SmallestLoader } from "components/shared/Loader";
import divideAndRoundUp from "./utils/divideAndRoundUp";
import splitIntoChunks from "./utils/splitIntoChunks";
import LinearProgressWithLabel from "components/shared/LinearProgressWithLabel/LinearProgressWithLabel";

const FileUploader: React.FC<FileUploaderProps> = ({ fileType, setMessage, setId, setShowUpload, setReload }) => {

  const [fileDetails, setFileDetails] = useState<any>(null);
  const [fileLoading, setFileLoading] = useState<any>(false);
  const [progressSteps, setProgressSteps] = useState<number>(1);
  const [progressStepPercentage, setProgressStepPercentage] = useState<number>(0);
  const [totalUpload, setTotalUpload] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [totalFilesAmount, setTotalFilesAmount] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [filesWithErrors, setFilesWithErrors] = useState<string[]>([]);
  const stepsCount = 2;

  useEffect(() => {
    setMessage({ type: 'none', message: '' });
    if (setReload) setReload(false);
  }, []);

  useEffect(() => {
    setProgressStepPercentage(parseFloat((100 / progressSteps).toFixed()));
  }, [progressSteps]);

  const handleFile = (event: any) => {
    if (fileType === 'pointCloud') {
      const filesUploaded: any = event.target.files;
      if (!filesUploaded && !fileDetails?.fileName) {
        setFileDetails(null);
      } else if (filesUploaded) {
        const uploadsCount = divideAndRoundUp(filesUploaded.length, stepsCount);
        setProgressSteps(uploadsCount);
        setTotalFilesAmount(filesUploaded.length);
        setFileDetails(splitIntoChunks(filesUploaded, stepsCount));
      }
    }
    else {
      const fileUploaded = event.target.files[0];
      if (!fileUploaded && !fileDetails?.fileName) {
        setFileDetails(null);
      } else if (fileUploaded) {
        const fileName = fileUploaded.name.toLowerCase();
        if (!fileName.endsWith('.mpp') && !fileName.endsWith('.pdf') && !fileName.endsWith('.jpg') && !fileName.endsWith('.png') && !fileName.endsWith('.csv')) {
          setFileDetails(null);
          setMessage({ type: 'error', message: "Please upload only mpp, pdf, jpg, png, or csv files" });
        } else {
          setFileDetails(fileUploaded);
          setMessage({ type: 'none', message: '' });
        }
      }
    }
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
    setFileLoading(true);

    if (fileType === 'pointCloud') {
      setShowProgressBar(true);
      try {
        let filesUploadedSoFar = 0; // Track progress using a variable
        for (const fileBundle of fileDetails) {
          const processedFiles = Object.values(fileBundle);
          const fileNames = processedFiles.map((file: any) => file.name);
          const formData = new FormData();
          processedFiles.forEach((file: any) => formData.append('file', file));

          try {
            // Upload the current file bundle
            const response = await uploadImagesForPointClouds(formData);

            if (response.status === 200) {
              // Update the progress tracking variable and set state to trigger a re-render
              filesUploadedSoFar += processedFiles.length;
              const newProgress = (filesUploadedSoFar / totalFilesAmount) * 100;

              setFilesUploaded(filesUploadedSoFar);
              setTotalUpload(newProgress);
            } else {
              throw new Error("Upload failed");
            }
          } catch (error: any) {
            setFilesWithErrors((prev) => [...prev, ...fileNames]);
          }
        }

        setMessage({ type: 'success', message: 'Files uploaded Successfully' });
      } catch (error) {
        setMessage({ type: 'error', message: "Something went wrong during the upload process." });
        setShowProgressBar(false);
      } finally {
        setFileLoading(false);
        if (setReload) setReload(true);
      }
    } else {
      // Single file upload logic (unchanged)
      const formData = new FormData();
      formData.append('file', fileDetails);
      const response = await uploadFile(fileType, formData);
      if (response.status === 200) {
        if (setId) {
          if (fileType === 'prefetch') {
            setId(response.data.uniqueId);
          }
          if (fileType === 'asset_doc') {
            setId(response.data.id);
          }
        }
        setMessage({ type: 'success', message: 'File uploaded Successfully' });
        if (setShowUpload) {
          setShowUpload(false);
        }
      } else {
        setMessage({ type: 'error', message: "Something went wrong" });
      }
      setFileLoading(false);
    }
  };

  useEffect(() => {
    if (totalUpload >= 100) {
      setShowProgressBar(false);
    }
  }, [totalUpload]);

  return (
    <>
      <form
        onSubmit={handleFileSubmit}
        className="grid gap-4 py-4"
      >
        <div className="gradient p-0.5 rounded-md relative">
          <div className="absolute py-1 px-2 bg-dark -top-4 left-2 text-[16px]">
            File Upload
          </div>
          <div className="flex flex-col gap-2 bg-dark p-3 pt-5 rounded-md">
            {fileLoading && <SmallestLoader />}
            <input
              name="fileUploader"
              onChange={handleFile}
              accept=".mpp,application/vnd.ms-project,.pdf,application/pdf,image/jpeg,image/png,text/csv"
              type="file"
              placeholder=""
              multiple
              className="col-span-3 input text-[16px]"
              required
            />
            {filesWithErrors.length > 0 && <span className="text-orange">Files were not uploaded: {filesWithErrors.join(', ')}</span>}
            {showProgressBar && <LinearProgressWithLabel value={totalUpload > 100 ? 100 : totalUpload} uploadingState={`${filesUploaded}/${totalFilesAmount}`} />}
            <div className="mt-2">
              <button
                className="btn"
                type="submit"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* {progressPopup && <ProgressPopup setProgressPopup={setProgressPopup} setFileLoading={setFileLoading} setId={setId} uuid={uuid} abortController={abortController} />} */}
    </>
  );
};

export default FileUploader;
