/* eslint-disable @typescript-eslint/no-unused-expressions */
import { progressApi } from "utilities/Axios";

export const getAllUnits = () => {
  return progressApi.get("/TagMeta/GetAllUnits");
};

export const getUnitsByTrainId = (trainId = 0) => {
  return progressApi.get(`/TagMeta/GetUnitsByTrainId?id=${trainId}`);
};

export const createUnit = (unit: any) => {
  return progressApi.post("/TagMeta/CreateUnit", unit);
};

export const updateUnit = (unit: any) => {
  return progressApi.post("/TagMeta/UpdateUnit", unit);
};

export const deleteUnit = (id: any) => {
  return progressApi.get(`/TagMeta/DeleteUnit?id=${id}`);
};
