import { useState, useEffect } from "react";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import { FiX } from "react-icons/fi";
import GanttChart from "components/charts/GanttChart/GanttChart";
import FileUploader from "components/FileUploader/FileUploader";
import { addProject, getAllProjects, getAllTasks } from "api/projectPlan";
import useUnity from "hooks/UseUnity";
import classNames from "utilities/ClassNames";

const GanttModal = ({ setSelectedTag, setModalType }: any) => {
  const { TimelineShown, setTimelineShown } = useUnity();
  const [message, setMessage] = useState({ type: 'none', message: '' });
  const [ganttFileId, setGanttFileId] = useState('');
  const [showUploader, setShowUploader] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [ganttData, setGanttData] = useState<any>(null);

  function sendCanvasVisibility(state: number) {
    window.vuplex.postMessage(JSON.stringify({ object: "CanvasVisibility", type: "CanvasVisibility", method: "SetStatus", arguments: [state] }));
  }

  useEffect(() => {
    setTimelineShown(false);
  }, []);

  useEffect(() => {
    if (window.vuplex) {
      if (location.pathname === '/3d-dashboard') {
        sendCanvasVisibility(0);
        return () => {
          sendCanvasVisibility(1);
        };
      }
      else {
        return () => {
          sendCanvasVisibility(0);
        };
      }
    }
  }, [window.vuplex, location.pathname]);

  useEffect(() => {
    const getProjectId = async (id: string) => {
      const response = await addProject(id);
      if (response.status === 200) {
        setProjectId(response.data.id);
      }
    };
    if (ganttFileId) {
      getProjectId(ganttFileId);
    } else {
      const getAllItems = async () => {
        const response = await getAllProjects();
        if (response.status === 200) {
          const allItems = response.data;
          setProjectId(allItems[allItems.length - 1].id);
        }
      };
      getAllItems();
    }
  }, [ganttFileId]);

  useEffect(() => {
    if (projectId) {
      const getAllTasksPerProject = async (id: string) => {
        const response = await getAllTasks(id);
        if (response.status === 200) {
          setGanttData(response.data);
        }
      };
      getAllTasksPerProject(projectId);
    }
  }, [projectId]);

  return (
    <div className="z-10 h-full max-h-[87vh] w-full opacity-95">
      {showUploader && <div className="h-full w-[95%] bg-dark absolute top-1 bottom-0 left-1 rounded opacity-50 z-40 pointer-events-none"></div>}
      <BorderGradient className="w-full h-max">
        <div className="w-full h-max p-3 bg-dark rounded-md">
          <div className="flex justify-between">
            <h3 className="text-2xl">Gantt Chart</h3>
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() => {
                setSelectedTag({ action: "", value: null });
                setModalType('');
              }}
            >
              <FiX size={20} />
            </button>
          </div>
          <div className="py-3 relative">
            {message.type !== 'none' && <div className={classNames(message.type === 'error' ? 'text-red' : 'text-green', 'mb-2')}>{message.message}</div>}
            <button className="btn mb-1 z-50" onClick={() => setShowUploader((prev) => !prev)}>
              {showUploader ? 'Cancel' : 'Upload File'}
            </button>
            {showUploader &&
              <div className="absolute top-11 right-0 left-0 z-50">
                <FileUploader fileType="prefetch" setMessage={setMessage} setId={setGanttFileId} setShowUpload={setShowUploader} />
              </div>}
          </div>
          <div className="h-[72vh] 2xl:h-[76vh] 3xl:h-[82vh] overflow-y-auto">
            {ganttData && <GanttChart data={ganttData} />}
          </div>
        </div>
      </BorderGradient>
    </div>
  );
};

export default GanttModal;
