import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { DataProvider } from "hooks/useData";
import { ContextProvider } from "utilities/ContextProvider";
import { UnityProvider } from "hooks/UseUnity";
import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider } from "react-oidc-context";
import { ScreenProvider } from "hooks/useScreen";
import ModalProvider from "hooks/ModalProvider";
import { CoordinatesProvider } from "hooks/useCoordinates";
import { TabProvider } from "hooks/useAssetsActiveTab";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_PM_APP}`,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppProvider>
        <UnityProvider>
          <TabProvider>
            <ModalProvider>
              <ScreenProvider>
                <CoordinatesProvider>
                  <DataProvider>
                    <ContextProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                    </ContextProvider>
                  </DataProvider>
                </CoordinatesProvider>
              </ScreenProvider>
            </ModalProvider>
          </TabProvider>
        </UnityProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
