const createMockData = ({ value }: any) => {
  return (
    {
      id: 0,
      formattedValue: "string",
      uom: "string",
      haveValue: true,
      value: value,
      minRange: 0,
      maxRange: 100,
      minValue: 0,
      maxValue: 100,
      name: "string",
      tagNameIdentifier: "string",
      desc: "string",
      isDisabled: true,
      isPinnedReact: true,
      isPinned3D: true,
      isTicketCreated: true,
      isAlertTag: true,
      plantId: 0,
      plantName: "string",
      trainId: 0,
      trainName: "string",
      unitId: 0,
      unitName: "string",
      equipmentId: 0,
      equipmentName: "string",
      equipmentBubbleId: 0,
      errorMessage: "string"
    }
  );
};

export default createMockData;