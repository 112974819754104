import FileExplorer from "components/FileExplorer/FileExplorer";

const PointsUploader = ({ reload, setReload }: any) => {
  return (
    <div>
      <FileExplorer type={'pointsUpload'} reload={reload} setReload={setReload} />
    </div>
  );
};

export default PointsUploader;