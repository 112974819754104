export const gaugeConfig = {
  regular: {
    titleSize: 'text-md 2xl:text-xl -mt-2 2xl:-mt-4',
    valueClasses: 'text-sm lg:top-[53%] lg-xl:top-[60%] lg-xl:text-md 2xl:top-[62%] 2xl:text-xl 4xl:text-2xl',
    labelClasses: '',
    doughtnutContainerClasses: 'w-full h-[95%] lg-xl:h-full -mt-4',
    splitsWidth: 1.5,
  },
  large: {
    titleSize: 'text-2xl lg-xl:text-3xl lg-xl:-mt-4 2xl:-mt-8',
    valueClasses: 'text-2xl lg-xl:text-2xl xl:top-[60%] top-[60%] lg-xl:top-[65%] 2xl:text-3xl 2xl:top-[63%] 3xl:text-4xl',
    labelClasses: '',
    doughtnutContainerClasses: 'h-[85%] lg-xl:h-full lg-xl:-mt-8 lg:w-full',
    splitsWidth: 3,
  },
  wide: {
    titleSize: 'text-xl -mt-3.5 xl:-mt-2',
    valueClasses: 'top-[56%] text-sm lg-xl:text-md xl:text-lg xl-2xl:text-xl xl-2xl:top-[58%] 2xl:text-2xl 2xl:top-[58%] 3xl:top-[63%] 4xl:top-[66%]',
    labelClasses: '',
    doughtnutContainerClasses: 'w-[72%] xl:w-[75%] xl-2xl:w-[70%] h-[100%] mx-auto -mt-4 2xl:-mt-6',
    splitsWidth: 2,
  },
  unity: {
    titleSize: 'text-lg -mt-2',
    valueClasses: 'top-[60%] left-[0%]',
    labelClasses: '',
    doughtnutContainerClasses: 'w-[60%] mb-6',
    splitsWidth: 1,
  },
  
};