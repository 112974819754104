import { FiHome, FiSave, FiX, FiZoomIn, FiZoomOut } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import BorderGradient from "./BorderGradient/BorderGradient";
import { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Loader, { SmallestLoader } from "./Loader";

export const PreviewFiles = ({ data, fileName, type, fileType, isModalOpen, setIsModalOpen, onLeftArrowClick, onRightArrowClick }: any) => {
  const [FileSrc, setFileSrc] = useState<any>(null);
  const [FileLoading, setFileLoading] = useState<any>(true);
  const [ZoomLevel, setZoomLevel] = useState<number>(0);

  const LoadPreviewFiles = async (fileType: any, download: any) => {
    setFileLoading(true);
    try {
      let blobUrl = "";
      if (fileType === "pdf") {
        const pdfBlob = new Blob([data], { type: "application/pdf" });
        blobUrl = URL.createObjectURL(pdfBlob);
        setTimeout(() => {
          setFileSrc(blobUrl);
        }, 100);
      } else if (fileType === "png" || fileType === "jpg") {
        if (typeof data === "string") {
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length).fill(0).map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const imageBlob = new Blob([byteArray], { type: `image/${fileType}` });
          blobUrl = URL.createObjectURL(imageBlob);

        } else {
          blobUrl = URL.createObjectURL(data);
        }
        setFileSrc(blobUrl);
      } else if (fileType === "txt" || fileType === 'csv') {
        const textBlob = new Blob([data], { type: fileType === "txt" ? "text/plain" : "text/csv" });
        blobUrl = URL.createObjectURL(textBlob);
        const reader = new FileReader();
        reader.onload = () => {
          setFileSrc(reader.result);
        };
        reader.readAsText(textBlob);
      }
      if (download) {
        const a = document.createElement('a');
        a.href = blobUrl;
        const name = fileName.split('.')[0];
        a.download = name + '.' + fileType;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error) {
      console.error("Error fetching the file:", error);
    } finally {
      setFileLoading(false);
    }
  };

  useEffect(() => {
    if (data !== null) {
      if (fileType && fileName) {
        LoadPreviewFiles(fileType, false);
      }

      return () => {
        setFileSrc(null);
      };
    }
  }, [fileType, fileName, data]);

  useEffect(() => {
    if (FileSrc) {
      setIsModalOpen(true);
    }
  }, [FileSrc, setIsModalOpen]);

  const zoomIn = () => {
    let z = ZoomLevel;
    if (z < 4) {
      z = z + 1;
    }
    setZoomLevel(z);
  };

  const zoomOut = () => {
    let z = ZoomLevel;
    if (z === 0) {
      setZoomLevel(0);
    }
    else {
      z = z - 1;
      setZoomLevel(z);
    }
  };

  const imgref: any = useRef(null);
  const [WidthImage, setWidthImage] = useState<any>(null);

  useEffect(() => {
    if (imgref.current && ZoomLevel === 0) {
      setWidthImage(imgref.current.offsetWidth);
    }

  }, [imgref.current, ZoomLevel]);


  return (
    <>
      {isModalOpen && (
        <div className="xl:h-[73vh] h-[72vh] z-50 w-[65vw]">
          <BorderGradient
            className={classNames("w-[65vw]", "xl:h-[73vh] h-[72vh]")}
          >
            <div className={classNames("h-full px-1 bg-dark rounded-md relative")}>
              {FileSrc && fileType === "png" && type === 'unity' && <FiChevronLeft className="absolute top-[45%] z-50 left-0 cursor-pointer" size={60} onClick={onLeftArrowClick} />}
              {FileSrc && fileType === "png" && type === 'unity' && <FiChevronRight className="absolute top-[45%] z-40 right-0 cursor-pointer" size={60} onClick={onRightArrowClick} />}
              <div className="flex justify-between p-2 w-full">
                {fileName ? <h3 className="text-xl w-[705px] text-ellipsis overflow-hidden whitespace-nowrap">{fileName}</h3> : <SmallestLoader />}
                <div className="flex justify-between gap-4">
                  {fileType === "png" && <div className="flex justify-between">
                    <FiZoomIn
                      className={classNames(
                        "p-1 mt-1 cursor-pointer hover:stroke-green",
                        ZoomLevel >= 4 ? "opacity-20" : ""
                      )}
                      color="white"
                      size={27}
                      onClick={zoomIn}
                    />
                    <FiZoomOut
                      className={classNames(
                        "p-1 mt-1 cursor-pointer hover:stroke-green",
                        ZoomLevel === 0 ? "opacity-20" : ""
                      )}
                      color="white"
                      size={27}
                      onClick={zoomOut}
                    />
                    <FiHome className={classNames(
                      "p-1 mt-1 cursor-pointer hover:stroke-green",
                    )}
                      color="white"
                      onClick={() => setZoomLevel(0)}
                      size={27} />
                    <FiSave className={classNames(
                      "p-1 mt-1 cursor-pointer hover:stroke-green",
                    )}
                      color="white"
                      onClick={() => LoadPreviewFiles(fileType, true)}
                      size={27} />
                  </div>
                  }
                  <button
                    className="cursor-pointer bg-green rounded-md p-1"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <FiX size={20} />
                  </button>
                </div>
              </div>
              {!FileSrc &&
                <div className="w-full h-full flex items-center justify-center">
                  <span className="-mt-32">
                    <Loader />
                  </span>
                </div>
              }
              {fileType === "png" &&
                FileSrc &&
                (ZoomLevel !== 0 ? (
                  <div className="overflow-scroll h-[93.5%] p-1" style={{ width: ZoomLevel === 0 ? '100%' : WidthImage + 3 + 'px' }} >
                    <div className="h-full" style={{ width: WidthImage + 200 * ZoomLevel + 'px' }}>
                      <img
                        src={FileSrc}
                        alt="Preview"
                      />
                    </div>
                  </div>

                ) : (
                  <img src={FileSrc} ref={imgref} alt="Preview" className="h-[92%] w-full" onLoad={() => {
                    if (imgref.current && ZoomLevel === 0) {
                      setWidthImage(imgref.current.offsetWidth);
                    }
                  }} />
                ))}
              {fileType === "pdf" && FileSrc && (
                <iframe
                  key={FileSrc} // Force rerender if src changes
                  src={FileSrc}
                  width="100%"
                  height="93%"
                  title="PDF Preview"
                ></iframe>
              )}
              {fileType === "txt" || fileType === "csv" && (
                <div className="w-[64vw] overflow-scroll h-[94%]">
                  <pre
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                    className="w-full"
                  >
                    {FileSrc}
                  </pre>
                </div>
              )}
            </div>
          </BorderGradient>
        </div>
      )}
    </>
  );
};
