/* eslint-disable @typescript-eslint/no-unused-expressions */
import { tr } from "date-fns/locale";
import { progressApi, authApi } from "utilities/Axios";

export const getAllUsersForAdmin = () => {
  return authApi.get("/api/user/UsersForAdmin");
};

export const UpdateUserRole = (data: any) => {
  return authApi.post("/api/user/assignrole", data,);
};

export const getRoles = () => {
  return authApi.get("/api/user/UserRoles");
};

export const getAllUsers = () => {
  return authApi.get("/api/user/Users");
};

export const deleteUser = (data: any) => {
  return progressApi.get("/user/deleteuser" + `?id=${data}`);
};
