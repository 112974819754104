// import { rejectTicket } from "api/tickets";

const rejectTicketService = async (id: any) => {
  // const response: any = await rejectTicket(id);
  // if (response.status === 200) {
  //   return { message: "success" };
  // }
  // if (response.status !== 200) {
  //   return { message: "failure" };
  // }
};

export default rejectTicketService;