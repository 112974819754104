import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useContext, useEffect, useRef, useState } from "react";

import { Doughnut } from "react-chartjs-2";
import classNames from "utilities/ClassNames";
import {
  calculateData,
  colorCodes,
} from "components/charts/Helpers";
import useScreen from "hooks/useScreen";
import { LuChevronRight, LuChevronLeft } from "react-icons/lu";
import createMockData from "./createMockData";
import { ModalContext } from "hooks/ModalProvider";

const theme = {
  fontFamily: "Cera-Pro",
  primary: "#65F4CD",
  text_grey: "white",
  "teal-color": "#00999f",
  secondary: "#4799E9",
  lightBlue: "#3f5173",
  red: "#f95460",
  orange: "#ffb554",
  danger: "#dc3545",
  grey: "#acb2c0",
  bodyBg: "#232323",
  bodyColor: "#434343",
  themeGradient:
    "linear-gradient(180deg, rgba(90, 85, 202, 0.6) 0%, rgba(90, 85, 202, 0.9) 22.58%, #5a55ca 100%)",
};

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const outOfThresholdRange = {
  BG: [colorCodes.white],
  value: [100],
  shadowBG: ["transparent"],
};

const UnityWidgetContainer = ({ mockData, name, units }: any) => {
  const data = createMockData({ value: mockData });
  const { modalType } = useContext(ModalContext);
  const { isScaled } = useScreen();
  const [valueShadowBG, setValueShadowBG] = useState(
    outOfThresholdRange.shadowBG
  );
  const [valueDataSet, setValueDataSet] = useState(outOfThresholdRange.value);
  const [valueBG, setValueBG] = useState(outOfThresholdRange.BG);

  const [isExpanded, setIsExpanded] = useState(true);
  const animatedDivRef = useRef(null);

  const [operatingDataSet, setOperatingDataSet] = useState([]);
  const [operatingBG, setOperatingBG] = useState([]);

  const [total, setTotal] = useState(0);
  const [disabled, setDisable] = useState(false);
  const [inRange, setInRange] = useState(true);

  const { minValue, maxValue, value, minRange, maxRange } = data;

  const [gradient, setGradient] = useState(null);

  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (data) {
      const calculatedData = calculateData(data, 100);

      setTotal(calculatedData.totalValue);
      setInRange(calculatedData.inRange);

      setOperatingDataSet(calculatedData.rangeSet.data);
      setOperatingBG(calculatedData.rangeSet.bg);

      if (!calculatedData.defaultDataSet) {
        setValueBG(calculatedData.valueSet.bg);
        setValueShadowBG(calculatedData.valueSet.shadowBg);
        setValueDataSet(calculatedData.valueSet.data);
      }

      setDisable(calculatedData.disable);
    }
  }, []);

  useEffect(() => {
    if (modalType==='gantt' || modalType === 'settings'){
      setIsExpanded(false);
    }
  }, [modalType]);
  

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart?.ctx;
      const gradientSegment = ctx.createLinearGradient(0, 0, 250, 0);
      const endBGData = valueDataSet[0] / total;

      const gradient = { start: theme.primary, end: theme.secondary };

      if (!inRange) {
        gradient.start = colorCodes.orange;
        gradient.end = colorCodes.red;
      }
      gradientSegment.addColorStop(0, gradient.start);
      gradientSegment.addColorStop(endBGData, gradient.end);

      setGradient(gradientSegment);
    }
  }, [valueBG, valueDataSet]);

  let bgGradient = [gradient];
  let borderBg: any = [gradient];
  if (valueBG.length === 2) {
    bgGradient = [gradient, valueBG[1]];
    borderBg = [gradient, "transparent"];
  } else if (valueBG.length === 1 && valueBG[0] === colorCodes.white) {
    bgGradient = valueBG;
    borderBg = ["transparent"];
  }

  // define gauge datasets - starts
  const gaugeData: any = {
    labels: [],
    datasets: [
      {
        data: valueDataSet,
        backgroundColor: bgGradient,
        hoverBackgroundColor: bgGradient,
        borderColor: borderBg,
        weight: 35,
        borderWidth: [5, 0],
        borderRadius: [5, 0],
        datalabels: {
          labels: {
            title: null,
          },
        },
        spacing: 0,
      },
      {
        data: [100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"],
        borderColor: ["transparent"],
        weight: 15,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  };
  // define gauge datasets - ends

  const fontSize = 12;

  const toggleAnimation = () => {
    const div: any = animatedDivRef.current;
    setIsExpanded(!isExpanded);
    if (div) {
      div.classList.toggle('w-0');
    }
  };

  function formatNumberWithCommas(number: number) {
    let numberString = number.toString();
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedValue = `${numberString} ${units}`;
    return formattedValue;
  }

  const formattedValue = formatNumberWithCommas(value);

  const homeClasses = classNames(
    isScaled ? (isExpanded ? 'w-64 h-64' : 'w-0 h-52') : (isExpanded ? 'w-56 h-50' : 'w-0 h-50'),
    'bgimg-1 w3-display-container w3-opacity-min',
    'bg-gray rounded-xl opacity-100 bg-opacity-70 overflow-hidden transition-width duration-500 ease-in-out'
  );

  return (
    <div className="flex items-center justify-center">
      <button className={classNames(isScaled ? "py-4 pl-4 pr-4" : "py-3 pl-1 pr-1.5", "bg-gray block h-min rounded-lg bg-opacity-70 hover:bg-opacity-80 overflow-hidden rounded-r-none")} onClick={toggleAnimation}>{isExpanded ? <LuChevronRight /> : <LuChevronLeft />}</button>
      <div ref={animatedDivRef} className={homeClasses}>
        <div className={classNames(isScaled ? "h-64 w-64" : "w-56 h-50")}>
          {total ? (
            operatingDataSet && (
              <div
                className={classNames(
                  isScaled ? " " : "px-2", "bg-transparent relative w-max max-w-full mx-auto -mt-4"
                )}
              >
                <Doughnut
                  ref={chartRef}
                  id={`gaugeChart_${data.id}`}
                  key={`gaugeChart_${name}_${data.id}`}
                  data={gaugeData}
                  options={{
                    rotation: -74,
                    circumference: 150,
                    cutout: "60%",
                    responsive: true,
                    animation: {
                      duration: 0,
                    },
                    aspectRatio: 1.5,
                  }}
                />
                <div className="flex flex-wrap items-center justify-center -mt-7">
                  <h4 className={classNames(isScaled ? "text-2xl" : "text-xl", "text-center text-green font-bold w-full mt-2")}>
                    {name}
                  </h4>
                  <span className={classNames(isScaled ? "text-2xl" : "text-xl", "font-bold w-max block")}>{formattedValue} </span>
                </div>
              </div>
            )
          ) : (
            <div className="w-full h-full flex flex-col items-center justify-center gap-5">
              <p>Range not available</p>
              <h4 className="text-center font-weight-bold">
                {formattedValue || "-"}
              </h4>
              <h4 className="text-center color-primary">{name}</h4>
            </div>
          )}
        </div>
      </div>
    </div >
  );
};
export default UnityWidgetContainer;