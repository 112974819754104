import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useApp from "hooks/useApp";
import { useEffect, useState } from "react";
import { getAllPointClouds, modifyPointCloud } from "api/timeline";
import { ColorOptions, colorOptions } from "./colorOptions";
import { getPointCloudData } from "pages/unity/domain/getPointCloudData";
import useUnity from "hooks/UseUnity";


export default function ColorDropdown({ id }: any) {

  const {
    selectedValues,
    setSelectedValues,
    setTimelineShown,
    setTimlineLoading,
    TimelineShown } = useUnity();

  const [current, setcurrent] = useState<keyof ColorOptions | null>("red");

  const Capitalize = (str: any) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleChange = async (Color: any) => {
    const currentcloud: any = selectedValues.filter((value: any) => value.id === id)[0];
    const response = await modifyPointCloud({ ...currentcloud, attribute: Color });
    if (response.status === 200) {
      if (TimelineShown) {
        setTimelineShown(false);
        setTimlineLoading(true);
        getPointCloudData(setSelectedValues);
        setTimeout(() => {
          setTimlineLoading(false);
          setTimelineShown(true);
        }, 1000);
      }
      else {
        getPointCloudData(setSelectedValues);
      }
    }
  };

  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      const curr = selectedValues.filter((value: any) => value.id === id);
      if (curr && curr.length > 0 && curr[0].attribute) {
        setcurrent(curr[0].attribute);
      }
    }
  }, [selectedValues]);


  return (
    <DropdownMenu>
      {
        <DropdownMenuTrigger asChild>
          <div
            className=""
            style={{
              border: "groove 2px",
              borderRadius: "6px",
              textAlign: "center",
              cursor: "pointer",
              fontSize: "13px",
              padding: "1px",
              width: '100px',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div className="flex items-center p-1 cursor-pointer hover:bg-gray-100">
              <div
                className={`w-7 h-1 mr-0`}
                style={{ backgroundColor: current ? colorOptions[current].color : 'unset' }}
              ></div>
              <span className="w-full">{current ? Capitalize(current) : 'None'}</span>
            </div>
          </div>
        </DropdownMenuTrigger>
      }
      <DropdownMenuContent align="end" className="bg-dark border-slate">
        {Object.entries(colorOptions).map(([key, value]: [any, any]) => (
          <DropdownMenuItem
            key={key}
            className="flex gap-1 items-center cursor-pointer hover:bg-lightGray"
            onClick={() => handleChange(key.toString())}
          >
            <div className="flex items-center  cursor-pointer hover:bg-gray-100">
              <div
                className={`w-3 h-3 mr-2`}
                style={{ backgroundColor: value.color }}
              ></div>
              <span>{Capitalize(key)}</span>
            </div>
          </DropdownMenuItem>
        ))}
        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
