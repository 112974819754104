import { progressApi as api } from "utilities/Axios";

export const getAllProjects = () => {
  const url = '/ProjectPlan/GetAllProjects';
  return api.get(url);
};

export const addProject = (id: string) => {
  const url = `/ProjectPlan/ProcessProjectFile?fileId=${id}`;
  return api.get(url);
};


export const getAllTasks = (id: string) => {
  const url = `/ProjectPlan/GetAllTask?projectId=${id}`;
  return api.get(url);
};