import { motion } from "framer-motion";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { LuChevronLeft } from "react-icons/lu";

const SideButtons = ({ closeMenu, showSideMenu }: any) => {
    const [currentoption, setcurrentoption] = useState<number>(1);
    const btns = [
        {
            name: '3D Model',
            desc: 'Planned Design',
            id: 3,
        },
        {
            name: 'As Built',
            desc: 'Progress Up To Date',
            id: 2,
        },
        {
            name: 'As Built VS Planned',
            desc: 'Mashup',
            id: 1,
        },
        {
            name: 'None',
            desc: 'Empty Area',
            id: 0,
        },
    ];
    const handleclick = (id: number) => {
        setcurrentoption(id);
        window.vuplex.postMessage(JSON.stringify({ object: "DemoPointCloud", type: "SimpleObjectSwitcher", method: "SwitchObjects", arguments: [id] }));
    };

    const slide = {
        enter: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.3,
            },
            display: "flex",
        },
        exit: {
            opacity: 0,
            x: "-100%",
            transition: {
                duration: 0.2,
            },
            transitionEnd: {
                display: "none",
            },
        },
    };
    const appear = {
        enter: {
            opacity: 1,
            transition: {
                duration: 0.3,
            },
            display: "block",
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.1,
                delay: 0.3,
            },
            transitionEnd: {
                display: "none",
            },
        },
    };
    // const getAlarmCount = async () => {
    //   const response: any = await getCount();
    //   if (response && response.status === 200) {
    //     setAlarmCount(response.data);
    //   }
    // };
    // useEffect(() => {
    //   getAlarmCount();
    // }, []);
    return (

        <motion.div
            initial="exit"
            animate={showSideMenu ? "enter" : "exit"}
            variants={appear}
            className="inset-0"
        // onClick={closeMenu}
        >
            <motion.div
                initial="exit"
                animate={showSideMenu ? "enter" : "exit"}
                variants={slide}
                className="absolute left-0 top-[80px] w-[260px] z-20"
            >
                <div className="flex">
                    <div className="min-w-max">
                        {btns.map((btn) => (
                            <div key={btn.id} className="flex items-center justify-between max-w-xs max-h-20 px-2 py-2 cursor-pointer" style={{ borderColor: currentoption == btn.id ? '#00fca9' : '', background: 'rgb(41,44,38,0.75)', borderTopRightRadius: btn.id == 3 ? '5px' : '', borderBottomRightRadius: btn.id == 0 ? '5px' : '' }} >
                                <div onClick={() => handleclick(btn.id)}>
                                    <div className="flex items-center ml-3 " style={{ color: currentoption == btn.id ? '#00fca9' : '' }} >
                                        <FaCheckCircle size="24" />

                                        <div className="flex flex-col mx-5">
                                            <h2 className="text-lg font-medium text-gray-700 sm:text-m dark:text-gray-200">{btn.name}</h2>
                                            <div className="text-xs -mx-4 text-blue-500 bg-gray-100 rounded-full sm:px-4 dark:bg-gray-700 ">
                                                {btn.desc}
                                            </div>
                                        </div>
                                    </div>

                                    {/* <h2 className="text-2xl font-semibold text-gray-500 sm:text-4xl dark:text-gray-300">$49 <span className="text-base font-medium">/Month</span></h2> */}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="h-[40px] w-[25px] flex items-start justify-center transition-all z-50 duration-3000 rounded-r-md  mt-14 pt-3 cursor-pointer" style={{background:'rgb(41,44,38,0.75)'}} onClick={closeMenu}><LuChevronLeft size="15" /></div>
                </div>
            </motion.div>
        </motion.div>
    );
};
export default SideButtons;

