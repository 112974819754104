import { progressApi } from "utilities/Axios";

export const getAllPointClouds = () => {
  return progressApi.get("/PointCloud/GetPointClouds");
};

export const getPointCloudbyId = (id: any) => {
  return progressApi.get("/PointCloud/GetPointCloud" + `?type=${id}`);
};

export const deletePointCloud = (id:any) => {
  return progressApi.get("/PointCloud/DeletePointCloud");
};

export const modifyPointCloud = (data:any) => {
  return progressApi.post("/PointCloud/ModifyPointCloud",data);
};
