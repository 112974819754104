import Slider from '@mui/material/Slider';
import { styled } from '@mui/system';
import pinIcon from 'assets/pinIcon.svg';

export const PrettoSlider = styled(Slider)({
  borderRadius: '3px',
  '& .MuiSlider-thumb': {
    borderRadius: '0',
    height: '50px',
    width: '50px',
    zIndex: '100',
    position: 'relative',
    top: '50px',
    backgroundImage: `url(${pinIcon})`,
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    '&::before': {
      content: '""',
      border: 'none',
      backgroundColor: 'none',
    },
    '&::after': {
      content: '""',
      border: 'none',
      backgroundColor: 'none',
    },
  },
  '& .MuiSlider-mark': {
    height: 10,
    width: 2,
    backgroundColor: '#FFFFFF',
    marginTop: '35px',
    '&:nth-child(22n+3)': {
      height: 25,
      marginTop: '35px',
    },
  },
  '& .MuiSlider-mark.pointCloudMarkRed': {
    backgroundColor: '#BC0000',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
    zIndex: 30,
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkRed': {
    backgroundColor: '#BC0000',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkGreen': {
    backgroundColor: '#048E00',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkGreen': {
    backgroundColor: '#048E00',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkPurple': {
    backgroundColor: '#9B00FF',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkPurple': {
    backgroundColor: '#9B00FF',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkBlue': {
    backgroundColor: '#00568E',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkBlue': {
    backgroundColor: '#00568E',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkOrange': {
    backgroundColor: '#FF9E00',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkOrange': {
    backgroundColor: '#FF9E00',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkYellow': {
    backgroundColor: '#FFD300',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkYellow': {
    backgroundColor: '#FFD300',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkPink': {
    backgroundColor: '#FF007F',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkPink': {
    backgroundColor: '#FF007F',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkCyan': {
    backgroundColor: '#00FFFF',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkCyan': {
    backgroundColor: '#00FFFF',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkBrown': {
    backgroundColor: '#8B4513',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkBrown': {
    backgroundColor: '#8B4513',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudMarkGrey': {
    backgroundColor: '#808080',
    height: 25,
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkGrey': {
    backgroundColor: '#808080',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-mark.pointCloudLabelledMarkMulti': {
    background: 'linear-gradient(to bottom, cyan 50%, red 40%)',
    height: 25,
    marginTop: '35px',
    width: 7,
    marginLeft: '-2.5px',
    borderRadius: '0.75rem',
  },
  '& .MuiSlider-markLabel': {
    color: '#FFFFFF',
    marginTop: '-15px',
    '&:nth-child(2n+1)': {
      display: 'none',
    },
  },
  '& .MuiSlider-track': {
    backgroundColor: 'black',
    opacity: 0,
    width: 1200,
    marginTop: '55px',
  },
  '& .MuiSlider-rail': {
    width: 1200,
    backgroundColor: 'black',
    opacity: 0.6,
    height: 70,
    marginLeft: '-50px',
    marginTop: '35px',
  },
});