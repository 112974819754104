const TextOverflow = (text: string, letters: number) => {
  if (text.toString().length > letters) {
    const slicedText = text.toString().slice(0, letters);
    return `${slicedText}...`;
  } else {
    return text;
  }
};

export default TextOverflow;
