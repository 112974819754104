import { deleteFile } from "api/fileManager";

const deleteDoc = async (selectedValue: any) => {
  const response: any = await deleteFile(selectedValue.fileId);
  if (response.status === 200) {
    return { message: "success" };
  }
  if (response.status !== 200) {
    return { message: "failure" };
  }
};

export default deleteDoc;