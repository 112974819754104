import { createContext, useContext, useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";

const PinsContext = createContext<any>(null);

export function PinsProvider({ children }: any) {
  const [loading, setLoading] = useState(true);
  const [pinLoading, setPinLoading] = useState<boolean>();
  const [allPinsMeta, setAllPinsMeta] = useState<any>(null);
  const [allPinsData, setAllPinsData] = useState<any>(null);
  const [unityPins, setUnityPins] = useState<any>(null);
  const [dashboardPins, setDashboardPins] = useState<any>(null);

  // const getAllPinsMeta = async () => {
  //   const response: any = await axios.get("/Dashboard/GetPinnedWidgets");
  //   if (response && response.status === 200) {
  //     setAllPinsMeta(response.data);
  //   }
  // };

  // useEffect(() => {
  //   getAllPinsMeta();
  // }, []);

  useEffect(() => {
    async function getPinData() {
      let a = allPinsMeta.length;
      const _pinsWithData: any = [];
      while (a >= 0) {
        if (allPinsMeta[a]?.tagId) {
          const response = await getTagData([allPinsMeta[a].tagId]);
          if (response && response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        if (allPinsMeta[a]?.expressionId) {
          const response = await getExpressionData([
            allPinsMeta[a].expressionId,
          ]);
          if (response && response.status === 200) {
            _pinsWithData.push({
              meta: allPinsMeta[a],
              data: response.data,
            });
          }
        }
        a--;
      }
      setAllPinsData(_pinsWithData);
    }
    if (allPinsMeta && allPinsMeta.length > 0) {
      getPinData();
    } else {
      setAllPinsData([]);
      setUnityPins([]);
      setDashboardPins([]);
    }
  }, [allPinsMeta]);

  useEffect(() => {
    if (allPinsData) {
      setUnityPins(
        allPinsData.filter((pin: any) => pin.meta.dashBoardType === 2)
      );
      setDashboardPins(
        allPinsData.filter((pin: any) => pin.meta.dashBoardType === 1)
      );
    }
    setLoading(false);
  }, [allPinsData]);

  // const pin = async (tagId: any, expressionId: any, dashBoardType: any) => {
  //   setPinLoading(true);
  //   if (dashBoardType === 2 && dashboardPins.length > 9) {
  //     return;
  //   }
  //   const response: any = await axios.post("/Dashboard/PinWidget", {
  //     tagId: tagId ? tagId : null,
  //     expressionId: expressionId ? expressionId : null,
  //     isPinned: true,
  //     dashBoardType,
  //   });
  //   if (response && response.status === 200) {
  //     getAllPinsMeta();
  //   }
  //   setPinLoading(false);
  // };

  // const pinTag = async (tagId: any, pinStatus: any) => {
  //   setPinLoading(true);
  //   const response: any = await axios.post("/Dashboard/PinWidget", {
  //     tagId: tagId,
  //     expressionId: null,
  //     isPinned: pinStatus,
  //     dashBoardType: 1,
  //   });
  //   if (response && response.status === 200) {
  //     getAllPinsMeta();
  //   }
  //   setPinLoading(false);
  // };

  // const unPin = async (tagId: any, expressionId: any, dashBoardType: any) => {
  //   setPinLoading(true);
  //   const response: any = await axios.post("/Dashboard/PinWidget", {
  //     tagId: tagId ? tagId : null,
  //     expressionId: expressionId ? expressionId : null,
  //     isPinned: false,
  //     dashBoardType,
  //   });
  //   if (response && response.status === 200) {
  //     getAllPinsMeta();
  //   }
  //   setPinLoading(false);
  // };

  return (
    <PinsContext.Provider
      value={{
        // pin,
        // unPin,
        // pinTag,
        unityPins,
        dashboardPins,
        loading,
        pinLoading,
      }}
    >
      {children}
    </PinsContext.Provider>
  );
}

export const usePins = () => useContext(PinsContext);

export default PinsContext;
