/* eslint-disable @typescript-eslint/no-unused-expressions */
import { progressApi } from "utilities/Axios";
import { ApiResponse, DynamicDashboardAPI, ExpPastDataAvgAPI, PinWidgetAPI, PlantTrainUnitEqAPI, TagPastDataAvgAPI, logsAPI } from "./types/apiTypes";


export const getWidgets = async (): Promise<DynamicDashboardAPI> => {
	return progressApi.get("/Dashboard/GetDashboardWidget");
};

export const updateWidgets = async (data: any) => {
	return progressApi.post("/Dashboard/UpdateDashboardWidget", data);
};

export const pinWidget = async (data: any) => {
	return progressApi.post(`/Dashboard/PinWidget`, data);
};

export const getPinnedWidgets = async (): Promise<PinWidgetAPI> => {
	return progressApi.get("/Dashboard/getPinnedWidgets");
};

export const getAllData = async (plantId = 1): Promise<PlantTrainUnitEqAPI> => {
	return progressApi.get(
		`/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant?plantId=${plantId}`
	);
};

export const getTagsHealth = async () => {
	return progressApi.get("/Dashboard/GetTagsHealth");
};

export const getExpressionFutureData = async (
	id: string,
	avgType: string
) => {
	let url = `/Dashboard/GetPastExpressionDataWithAvgFuture?expressionId=${id}`;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return progressApi.get(url);
};

export const getTagsPastData = async (
	id: string,
	days: number,
	avgType: string
): Promise<TagPastDataAvgAPI> => {
	let url = `/Dashboard/GetTagPastDataWithAvg?tagId=${id}`;
	days ? (url += `&pastNumberOfDays=${days}`) : null;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return progressApi.get(url);
};

export const getExpressionPastData = async (
	id: string,
	days: number,
	avgType: string
): Promise<ExpPastDataAvgAPI> => {
	let url = `/Dashboard/GetPastExpressionDataWithAvg?expressionId=${id}`;
	days ? (url += `&pastNumberOfDays=${days}`) : null;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return progressApi.get(url);
};

export const getSystemLogs = ({ durType = 3, count = 10, action = null, module = null }): Promise<logsAPI> => {
	let url: any = `/SystemLog/GetSystemLogsByDateTime` + `?durType=${durType}&count=${count}`;
	if (module != null) {
		url += `&module=${module}`;
	}
	if (action != null) {
		url += `&action=${action}`;
	}
	return progressApi.get(url);
};

export const getTagFutureData = async (
	id: string,
	avgType: string
) => {
	let url = `/Dashboard/GetTagPastDataWithAvgFuture?tagId=${id}`;
	avgType ? (url += `&avgType=${avgType}`) : null;
	return progressApi.get(url);
};