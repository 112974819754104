function findClosestLessThan(value: number, items: number[]) {
  let closest = 0;

  items.forEach(item => {
    if (item < value && (closest === null || value - item < value - closest)) {
      closest = item;
    }
  });

  return closest;
}

export default findClosestLessThan;