import classNames from "utilities/ClassNames";


const ScrollLine = ({ currentSegment, totalSegments }:any) => {
  return (
    <div className="flex h-2 p-0.5 mt-3 rounded-md w-full bg-black absolute">
      {Array.from({ length: totalSegments }).map((_, index) => (
        <div
          key={index}
          className={classNames(`h-full flex-1`,
            index == currentSegment ? "bg-white" : "bg-gray")}
        />
      ))}
    </div>
  );
};

export default ScrollLine;
