import React, { createContext } from "react";
import { ActionName } from "types/common";

type ModalContextType = {
  modalType: any;
  setModalType: any;
};

export const ModalContext = createContext<ModalContextType>({
  modalType: '',
  setModalType: () => { },
});

const ModalProvider = ({ children }: any) => {
  const [modalType, setModalType] = React.useState<ActionName | ''>('');

  return (
    <ModalContext.Provider value={{ modalType, setModalType }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
