/* eslint-disable @typescript-eslint/no-unused-expressions */
import { progressApi } from "utilities/Axios";

export const getTrains = () => {
  return progressApi.get(`/TagMeta/GetTrainsByPlantId?id=${1}`);
};

export const getAllTrains = () => {
  return progressApi.get(`/TagMeta/GetAllTrains`);
};

export const createTrain = (train: any) => {
  return progressApi.post("/TagMeta/CreateTrain", train);
};

export const updateTrain = (train: any) => {
  return progressApi.post("/TagMeta/UpdateTrain", train);
};

export const deleteTrain = (id: number) => {
  return progressApi.get(`/TagMeta/DeleteTrain?id=${id}`);
};
