// import { deleteTicket } from "api/tickets";

const deleteTicketService = async (id: any) => {
  // const response: any = await deleteTicket(id);
  // if (response.status === 200) {
  //   return { message: "success" };
  // }
  // if (response.status !== 200) {
  //   return { message: "failure" };
  // }
};

export default deleteTicketService;