import { Gantt, ViewMode } from 'gantt-task-react';
import { useEffect, useState } from 'react';
import { MdExpandMore, MdArrowBack } from 'react-icons/md';
import { SmallestLoader } from 'components/shared/Loader';
import "gantt-task-react/dist/index.css";
import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import { ReactComponent as ArrowRightIcon } from "assets/arrowRight.svg";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import classNames from 'utilities/ClassNames';

const ProjectTitleInfo = ({ projectInfo }: any) => {
  const startDate = new Date(projectInfo.start);
  const finishDate = new Date(projectInfo.finish);

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const finishYear = finishDate.getFullYear();
  const finishMonth = finishDate.getMonth();

  const durationInMonths = (finishYear - startYear) * 12 + (finishMonth - startMonth) + 1;

  return (
    <div className='font-bold flex gap-5 text-lg'>
      <h3>{projectInfo.name}</h3>
      <span>Start: {startDate.toDateString()}</span>
      <span>Finish: {finishDate.toDateString()}</span>
      <span>Duration: {durationInMonths} {durationInMonths === 1 ? 'Month' : 'Months'}</span>
    </div>
  );
};

const genTodayPosition = ({ startPos, mode }: any) => {
  const oneUnitDuration: any = {
    Day: 250,
    Week: 250,
    Month: 100,
    Year: 250,
  };

  return Number(startPos) + oneUnitDuration[mode];
};

const getUniqueDependencies = (data: any) => {
  const allDependencies = data.flatMap((item: any) => item.parentTaskId);
  const uniqueDependencies = [...new Set(allDependencies)];
  return uniqueDependencies;
};

const Header = () => {
  return (
    <div className="_3_ygE font-sans text-sm">
      <div className="_1nBOt h-[48px]">
        <div className="_2eZzQ h-[25px] mt-2.5">
        </div>
        <div className="_WuQ0f min-w-[40px] border-r-white border-r-[1px]">
        </div>
        <div className="_WuQ0f min-w-[210px]">
          Name
        </div>
        <div className="_2eZzQ h-[25px] mt-2.5">
        </div>
        <div className="_WuQ0f min-w-[120px]">
          From
        </div>
        <div className="_2eZzQ h-[25px] mt-2.5">
        </div>
        <div className="_WuQ0f min-w-[120px]">
          To
        </div>
      </div>
    </div>
  );
};


const GanttChart = ({ data }: any) => {
  const [readyData, setReadyData] = useState<any>([]);
  const [viewMode, setViewMode] = useState(ViewMode.Month);
  const [mainTaskId, setMainTaskId] = useState<string>('');
  const [displayedTasks, setDisplayedTasks] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [projectInfo, setProjectInfo] = useState<any>(null);
  const [allDependencies, setAllDependencies] = useState<any>([]);
  const [prepardData, setPreparedData] = useState([]);

  useEffect(() => {
    const processedData = data.map((item: any) => {
      const parent = data.find((single: any) => single.id === item.parentTaskId);
      return ({
        ...item,
        id: item.taskIndex,
        parentTaskId: parent ? parent.taskIndex : null,
      });
    });
    setPreparedData(processedData);
  }, [data]);

  useEffect(() => {
    setAllDependencies(getUniqueDependencies(prepardData));
  }, [prepardData]);

  useEffect(() => {
    if (prepardData.length > 0) {
      const projectFile: any = prepardData.find((item: any) => !item.parentTaskId);
      const dataWithoutProjectName = prepardData.filter((item: any) => item.parentTaskId);
      setProjectInfo(projectFile);

      const processedData = dataWithoutProjectName.map((item: any, index, array: any) => {
        const isSubTask = item.parentTaskId && item.parentTaskId !== projectFile.id;
        const isEnabled = allDependencies.includes(item.id);

        return {
          start: new Date(item.start),
          end: new Date(item.finish),
          name: (
            <>
              {isSubTask && item.id !== mainTaskId && <ArrowRightIcon className="min-w-4 min-h-4 max-h-4 max-w-4" />}{item.name}
            </>
          ),
          id: item.id,
          type: isSubTask ? 'task' : 'project',
          progress: 50,
          isDisabled: !isEnabled,
          isClickable: isEnabled,
          styles: {
            backgroundColor: isEnabled ? '#00B3B3' : '#E5F0EF',
            backgroundSelectedColor: isEnabled ? '#00B3B3' : '#E5F0EF',
            progressColor: isEnabled ? '#006666' : '#809999',
            progressSelectedColor: isEnabled ? '#006666' : '#809999',
          },
          parents: item.parentTaskId ? [item.parentTaskId.toString()] : index > 0 ? [array[index - 1].id.toString()] : [],
          dependencies: isSubTask ? [item.lastPredecessorIndex || null] : null,
          visible: !item.parentTaskId || item.parentTaskId === projectFile.id,
          order: item.taskIndex - 1,
        };
      });

      setReadyData(processedData);
      setDisplayedTasks(processedData);
    }
  }, [prepardData, allDependencies, mainTaskId]);

  useEffect(() => {
    setIsLoading(displayedTasks.length === 0);
  }, [displayedTasks]);

  useEffect(() => {
    if (mainTaskId !== '') {
      const relatedTasks = readyData.filter((item: any) => item.id === mainTaskId || item.parents?.includes(mainTaskId.toString()));
      const tasksWithVisibility = relatedTasks.map((task: any) => {
        if (task.id === mainTaskId) {
          return { ...task, visible: true, type: 'project' as const };
        }
        return { ...task, visible: true, type: 'task' as const };
      });
      setDisplayedTasks(tasksWithVisibility);
    } else {
      setDisplayedTasks(readyData);
    }
  }, [mainTaskId, readyData]);

  const handleTaskClick = (task: any) => {
    if (task.type === 'project') {
      const updatedData = displayedTasks.map((t: any) => {
        if (t.parents.includes(task.id.toString())) {
          return { ...t, visible: !t.visible, order: t.order };
        }
        return t;
      });
      setDisplayedTasks(updatedData);
    }

    if (task.type === 'task') {
      if (task.isClickable) {
        setMainTaskId(task.id);
      }
    }
  };

  const handleBackClick = () => {
    const item: any = displayedTasks.find((task: any) => task.id === mainTaskId.toString());
    if (item && item.parents && item.parents.length > 0 && item.parents[0] !== projectInfo.id) {
      setMainTaskId(item.parents[0]);
    } else {
      setMainTaskId('');
    }
  };

  const preparedTasks = (tasks: any) => {
    const readyTasks = tasks.filter((task: any) => task.visible);
    return readyTasks;
  };

  useEffect(() => {
    let lineElement: SVGLineElement | null = null;
    let svgIconElement: SVGSVGElement | null = null;
    let minValueIcon: SVGLineElement | null = null;
    let maxValueIcon: SVGLineElement | null = null;
    let currentDate: any = null;

    const svgString = '<?xml version="1.0" ?><svg height="32" id="triangle-down" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M4 8 H28 L16 26 z" fill="cyan"/></svg>';

    const updateLineElement = () => {
      setTimeout(() => {
        const todayElement = document.querySelector('.today');
        const titlesContainer = document.querySelector('.calendar');
        const dateToday = new Date();
        let x = 0;
        let y = 0;
        let minXValue = 0;
        let maxXValue = 0;

        // Remove existing elements if any
        if (lineElement && lineElement.parentNode) {
          lineElement.parentNode.removeChild(lineElement);
        }
        if (svgIconElement && svgIconElement.parentNode) {
          svgIconElement.parentNode.removeChild(svgIconElement);
        }
        if (minValueIcon && minValueIcon.parentNode) {
          minValueIcon.parentNode.removeChild(minValueIcon);
        }
        if (maxValueIcon && maxValueIcon.parentNode) {
          maxValueIcon.parentNode.removeChild(maxValueIcon);
        }
        if (currentDate && currentDate.parentNode) {
          currentDate.parentNode.removeChild(currentDate);
        }

        document.querySelectorAll('.customTodayTooltip').forEach((el) => el.remove());
        document.querySelectorAll('.customIconTop').forEach((el) => el.remove());
        document.querySelectorAll('.customTodayDate').forEach((el) => el.remove());

        if (todayElement) {

          const { minX, maxX } = findMinMaxXValues();
          const rectElement = todayElement.querySelector('rect');
          if (rectElement) {
            const x1 = rectElement.getAttribute('x');
            const y1 = rectElement.getAttribute('height');
            x = Number(x1);
            y = 10000;
            minXValue = minX;
            maxXValue = maxX;
          }
        }

        const elements = document.querySelectorAll('text._9w8d5');

        elements.forEach(element => {
          element.setAttribute('y', '30');
        });

        const textElements = document.querySelectorAll('text._3KcaM');

        textElements.forEach(textElement => {
          textElement.setAttribute('fill', 'white');
        });
        const gElements = document.querySelectorAll('g._KxSXS');
        const pElements = document.querySelectorAll('g._1KJ6x');

        gElements.forEach(g => {
          const rectElement = g.querySelector('rect._31ERP');

          if (rectElement && rectElement.getAttribute('fill') === '#E5F0EF') {
            const textElement = g.parentElement?.querySelector('text._3zRJQ');
            if (textElement) {
              textElement.setAttribute('class', '_3zRJQ _newFill');
            }
          }
        });

        pElements.forEach(p => {
          const rectElement = p.querySelector('rect._2pZMF');

          if (rectElement && rectElement.getAttribute('fill') === '#E5F0EF') {
            const textElement = p.parentElement?.querySelector('text._3zRJQ');
            if (textElement) {
              textElement.setAttribute('class', '_3zRJQ _newFill');
            }
          }
        });

        // Create line element
        lineElement = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        lineElement.setAttribute('x1', genTodayPosition({ startPos: x, mode: viewMode }).toString());
        lineElement.setAttribute('y1', '0');
        lineElement.setAttribute('stroke-width', '1');
        lineElement.setAttribute('x2', genTodayPosition({ startPos: x, mode: viewMode }).toString());
        lineElement.setAttribute('y2', y.toString());
        lineElement.setAttribute('class', 'customTodayTooltip');

        currentDate = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        currentDate.setAttribute('x', (genTodayPosition({ startPos: x, mode: viewMode }) - 50).toString());
        currentDate.setAttribute('y', '45');
        currentDate.setAttribute('fill', '#ffffff');
        currentDate.setAttribute('class', 'customTodayDate');
        currentDate.textContent = dateToday.toDateString();

        minValueIcon = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        minValueIcon.setAttribute('x1', minXValue.toString());
        minValueIcon.setAttribute('y1', '0');
        minValueIcon.setAttribute('stroke-width', '1');
        minValueIcon.setAttribute('x2', minXValue.toString());
        minValueIcon.setAttribute('y2', y.toString());
        minValueIcon.setAttribute('class', 'customTodayTooltip customTooltipMin');

        maxValueIcon = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        maxValueIcon.setAttribute('x1', maxXValue.toString());
        maxValueIcon.setAttribute('y1', '0');
        maxValueIcon.setAttribute('stroke-width', '1');
        maxValueIcon.setAttribute('x2', maxXValue.toString());
        maxValueIcon.setAttribute('y2', y.toString());
        maxValueIcon.setAttribute('class', 'customTodayTooltip customTooltipMax');

        // Create SVG icon element
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        svgIconElement = svgDoc.documentElement as unknown as SVGSVGElement;
        svgIconElement.setAttribute('x', (genTodayPosition({ startPos: x, mode: viewMode }) - 16).toString());
        svgIconElement.setAttribute('y', '-11');
        svgIconElement.setAttribute('class', 'customIconTop');

        todayElement?.append(lineElement);
        todayElement?.append(svgIconElement);
        todayElement?.append(minValueIcon);
        todayElement?.append(maxValueIcon);
        titlesContainer?.append(currentDate);
      }, 50);
    };

    const observer = new MutationObserver(updateLineElement);

    const targetNode = document.querySelector('.bar');
    const config = { childList: true, subtree: true };

    if (targetNode) {
      observer.observe(targetNode, config);
    }

    // Initial run to handle the case when the component mounts
    updateLineElement();

    return () => {
      observer.disconnect();
      if (lineElement && lineElement.parentNode) {
        lineElement.parentNode.removeChild(lineElement);
      }
      if (svgIconElement && svgIconElement.parentNode) {
        svgIconElement.parentNode.removeChild(svgIconElement);
      }
      if (minValueIcon && minValueIcon.parentNode) {
        minValueIcon.parentNode.removeChild(minValueIcon);
      }
      if (maxValueIcon && maxValueIcon.parentNode) {
        maxValueIcon.parentNode.removeChild(maxValueIcon);
      }
      if (currentDate && currentDate.parentNode) {
        currentDate.parentNode.removeChild(currentDate);
      }
    };
  }, [viewMode, displayedTasks]);

  // Function to find min and max X values
  function findMinMaxXValues() {
    const elements = document.querySelectorAll('._1KJ6x');
    let minX = Infinity;
    let maxX = -Infinity;

    elements.forEach(element => {
      const rects = element.querySelectorAll('rect');
      rects.forEach((rect: any) => {
        const x = parseFloat(rect.getAttribute('x'));
        const xWidth = parseFloat(rect.getAttribute('width'));
        if (!isNaN(x)) {
          if (x < minX) minX = x;
          if (x > maxX) maxX = x + xWidth;
        }
      });
    });

    return { minX, maxX };
  }

  const TaskTable = ({ tasks }: any) => {
    const [hoveredTaskId, setHoveredTaskId] = useState<string | null>(null);

    const formatData = (data: any) => {
      if (data instanceof Date) {
        return data.toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });
      }
      return data;
    };

    const SingleItem = ({ task, item }: any) => {
      const isHovered = hoveredTaskId === task.id;
      // const isHovered = true;
      const genContainerClases = (columnItem: any) => {
        if (columnItem === 'order') return 'min-w-[40px] max-w-[40px] border-r-white border-r-[1px]';
        if (columnItem === 'start' || columnItem === 'end') return classNames(isHovered ? '' : 'border-r-white border-r-[1px]', 'min-w-[100px] max-w-[100px]');
        return classNames(isHovered ? '' : 'border-r-white border-r-[1px]', 'min-w-[250px] max-w-[250px]');
      };

      return (
        <div
          className={classNames(genContainerClases(item), '_3lLk3')}
          onMouseEnter={() => item === 'name' && setHoveredTaskId(task.id)}
          onMouseLeave={() => setHoveredTaskId(null)}
        >
          <div className={classNames(task.isClickable ? 'text-green font-bold' : '', isHovered && item === 'name' ? 'absolute -mt-2.5' : '', '_nI1Xw')}>
            <div className={classNames(item === 'order' ? 'flex justify-center' : 'ml-2', 'w-full h-full')}>
              {item === 'name' || item === 'order' || !isHovered ? formatData(task[item]) : null}
            </div>
          </div>
        </div>
      );
    };

    const Content = () => {
      return tasks.map((task: any) => (
        <div className='_34SS0 h-[50px] hover:bg-blue hover:bg-opacity-20' key={task.id} onClick={() => handleTaskClick(task)}>
          <SingleItem task={task} item='order' />
          <SingleItem task={task} item='name' />
          <SingleItem task={task} item='start' />
          <SingleItem task={task} item='end' />
        </div>
      ));
    };

    return (
      <div className='_2B2zv'>
        <div className='_3ZbQT font-sans text-sm'>
          <Content />
        </div>
      </div>
    );
  };

  return (
    <div className='bg-dark'>
      {displayedTasks.length > 0 &&
        <>
          <div className='flex justify-between'>
            <div className='flex items-center'>
              <button
                className="flex justify-center items-center gap-1 text-green"
                onClick={handleBackClick}
              >
                {mainTaskId !== '' && <MdArrowBack size={25} />}
                {isLoading && <SmallestLoader />}
              </button>
              {projectInfo && <ProjectTitleInfo projectInfo={projectInfo} />}
            </div>
            <div style={{ marginBottom: '10px' }} className='flex gap-4 items-center justify-center'>
              <span>Choose the dates format:</span>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div
                    style={{
                      borderRadius: "3px",
                      textAlign: "center",
                      cursor: "pointer",
                      fontSize: '16px',
                      background: '#004F4F',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    className='w-24 h-10 pl-1.5'
                  >
                    {viewMode}
                    <MdExpandMore size={20} />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='bg-black border-none mr-6'>
                  <DropdownMenuItem
                    className="flex gap-2 items-center cursor-pointer p-2 mb-1 bg-[#004F4F]"
                    onClick={() => setViewMode(ViewMode.Day)}
                  >
                    <div style={{ display: "flex", gap: "5px" }}>
                      Day
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="flex gap-2 items-center cursor-pointer p-2 mb-1 bg-[#004F4F]"
                    onClick={() => setViewMode(ViewMode.Week)}
                  >
                    Week
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="flex gap-2 items-center cursor-pointer p-2 mb-1 bg-[#004F4F]"
                    onClick={() => setViewMode(ViewMode.Month)}
                  >
                    Month
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="flex gap-2 items-center cursor-pointer p-2 bg-[#004F4F]"
                    onClick={() => setViewMode(ViewMode.Year)}
                  >
                    Year
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

          </div>
          <div className='bg-dark dark custom-scroll'>
            <Gantt
              tasks={preparedTasks(displayedTasks)}
              viewMode={viewMode}
              listCellWidth="150px"
              todayColor={'transparent'}
              columnWidth={viewMode === ViewMode.Month ? 200 : ViewMode.Year ? 500 : 65}
              barCornerRadius={4}
              arrowColor='#D3D3D3'
              barBackgroundColor="#14b8a6"
              TooltipContent={({ task }) => (
                <BorderGradient>
                  <div style={{ padding: '5px', backgroundColor: '#111' }} className='rounded-md'>
                    <strong>{task.name}</strong>
                    <p>Start: {task.start.toDateString()}</p>
                    <p>End: {task.end.toDateString()}</p>
                    <p>Duration: {Math.floor((new Date(task.end).getTime() - new Date(task.start).getTime()) / (1000 * 60 * 60 * 24))} days</p>
                  </div>
                </BorderGradient>
              )}
              TaskListHeader={Header}
              TaskListTable={TaskTable}
              onClick={handleTaskClick}
            />
          </div>
        </>
      }
    </div>
  );
};

export default GanttChart;
