const downloadCSV = (csvString: any,name?:any) => {
  const blob = new Blob([csvString], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', name?name:'nullTagData.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default downloadCSV;