import { createContext, useContext, useEffect, useState } from "react";

export const ScreenContext = createContext<any | null>(null);

export const ScreenProvider = ({ children }: { children: any }) => {
  const [isScaled, setIsScaled] = useState<boolean>(window.innerWidth < 1550);

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsScaled(window.innerWidth < 1550);
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <ScreenContext.Provider
      value={{
        isScaled,
        setIsScaled,
      }}
    >
      {children}
    </ScreenContext.Provider>
  );
};

export default function useScreen() {
  return useContext(ScreenContext);
}