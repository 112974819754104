import { createTag, updateTag } from "api/tags";
import { updateTrain, createTrain } from "api/trains";
import { updateUnit, createUnit } from "api/units";
import { mapAssetDocs } from "api/docs";
import { updateEquipment, createEquipment } from "api/equipments";
// import { getAllTrains } from "api/trains";
import { getAllUnits } from "api/units";
import { createTicket } from "api/tickets";

export const actionConfig = {
  tags: {
    edit: {
      title: 'Edit Tag',
      formInputs: [
        { name: 'Tag', id: 'tagNameIdentifier', type: "text", placeHolder: 'Tag', isRequired: true, itemWidth: 'full' },
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Tag Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Engineering Unit', id: 'uom', placeHolder: 'Engineering Unit', type: "text", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: true,
      rangesInputs: [
        { name: 'Min Value', id: 'minValue', placeHolder: 'Min Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Value', id: 'maxValue', placeHolder: 'Max Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Range', id: 'minRange', placeHolder: 'Min Range', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Range', id: 'maxRange', placeHolder: 'Max Range', type: "number", isRequired: true, itemWidth: 'half' },
      ],
      submitBtn: 'Update',
      submitAction: updateTag,
      isFileUpload: true,
      hasSelects: false,
      selects: [],
    },
    new: {
      title: 'New Tag',
      formInputs: [
        { name: 'Tag', id: 'tagNameIdentifier', placeHolder: 'Tag', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Tag Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Engineering Unit', id: 'uom', placeHolder: 'Engineering Unit', type: "text", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: true,
      rangesInputs: [
        { name: 'Min Value', id: 'minValue', placeHolder: 'Min Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Value', id: 'maxValue', placeHolder: 'Max Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Range', id: 'minRange', placeHolder: 'Min Range', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Range', id: 'maxRange', placeHolder: 'Max Range', type: "number", isRequired: true, itemWidth: 'half' },
      ],
      submitBtn: 'Create',
      submitAction: createTag,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'complex'
  },
  expressions: {
    edit: {
      title: 'Edit Expression',
      formInputs: [
        { name: 'Expression Name', id: 'name', placeHolder: 'Expression Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
      ],
      hasRanges: true,
      rangesInputs: [
        { name: 'Engineering Unit', id: 'uom', placeHolder: 'Engineering Unit', type: "text", isRequired: true, itemWidth: 'half' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Value', id: 'minValue', placeHolder: 'Min Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Value', id: 'maxValue', placeHolder: 'Max Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Range', id: 'minRange', placeHolder: 'Min Range', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Range', id: 'maxRange', placeHolder: 'Max Range', type: "number", isRequired: true, itemWidth: 'half' },
      ],
      submitBtn: 'Update',
      submitAction: updateTag,
      isFileUpload: true,
      hasSelects: false,
      selects: [],
    },
    new: {
      title: 'New Expression',
      formInputs: [
        { name: 'Expression Name', id: 'name', placeHolder: 'Expression Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
      ],
      hasRanges: true,
      rangesInputs: [
        { name: 'Engineering Unit', id: 'uom', placeHolder: 'Engineering Unit', type: "text", isRequired: true, itemWidth: 'half' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Value', id: 'minValue', placeHolder: 'Min Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Value', id: 'maxValue', placeHolder: 'Max Value', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Min Range', id: 'minRange', placeHolder: 'Min Range', type: "number", isRequired: true, itemWidth: 'half' },
        { name: 'Max Range', id: 'maxRange', placeHolder: 'Max Range', type: "number", isRequired: true, itemWidth: 'half' },
      ],
      submitBtn: 'Create',
      submitAction: createTag,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'complex'
  },
  trains: {
    edit: {
      title: 'Edit Train',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Update',
      submitAction: updateTrain,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    new: {
      title: 'New Train',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Create',
      submitAction: createTrain,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'simple',
  },
  units: {
    title: 'Units',
    edit: {
      title: 'Edit Unit',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Update',
      submitAction: updateUnit,
      isFileUpload: false,
      hasSelects: true,
      selects: [
        // { name: 'trains', id: 'trainId', getItems: getAllTrains },
      ],
    },
    new: {
      title: 'New Unit',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Create',
      submitAction: createUnit,
      isFileUpload: false,
      hasSelects: false,
      selects: []
    },
    modalType: 'simple',
  },
  equipments: {
    title: 'Equipments',
    edit: {
      title: 'Edit Equipment',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true, itemWidth: 'full' },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Update',
      submitAction: updateEquipment,
      isFileUpload: false,
      hasSelects: true,
      selects: [
        // { name: 'trains', id: 'trainId', getItems: getAllTrains, items: null },
        { name: 'Units', id: 'unitId', getItems: getAllUnits, items: null },
      ],
    },
    new: {
      title: 'New Equipment',
      formInputs: [
        { name: 'Name', id: 'name', placeHolder: 'Name', type: "text", isRequired: true },
        { name: 'Description', id: 'desc', placeHolder: 'Description', type: "text", isRequired: false, itemWidth: 'full' },
        { name: 'Sequence Order', id: 'seqOrder', placeHolder: 'Sequence Order', type: "number", isRequired: true, itemWidth: 'full' },
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Create',
      submitAction: createEquipment,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'simple',
  },
  tickets: {
    title: 'Tickets',
    new: {
      title: 'Add trouble ticket',
      formInputs: [
        { name: 'Issue Title', id: 'title', placeholder: '', type: 'text', isRequired: true },
        { name: 'Issue description', id: 'description', placeHolder: '', type: "text", isRequired: true },
        { name: 'Resolution', id: 'comments', placeHolder: '', type: "text", isRequired: true }
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Create',
      submitAction: createTicket,
      isFileUpload: false,
      hasSelects: true,
      selects: [
        { name: 'Severity', id: 'severity', getItems: null, items: [{ id: '1', name: 1 }, { id: '2', name: 2 }, { id: '3', name: 3 }, { id: '4', name: 4 }] },
        { name: 'Priority', id: 'priority', getItems: null, items: [{ id: '1', name: 'Hight' }, { id: '2', name: 'Medium' }, { id: '3', name: 'Low' }] },
      ],
    },
    edit: {
      title: '',
      formInputs: [
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: '',
      submitAction: null,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'simple',
  },
  docs: {
    title: 'document',
    new: {
      title: 'Add Document',
      formInputs: [
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: 'Add Document',
      submitAction: mapAssetDocs,
      isFileUpload: true,
      hasSelects: false,
      selects: [
      ],
    },
    edit: {
      title: '',
      formInputs: [
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: '',
      submitAction: null,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'simple',
  },
  alarms: {
    new: {
      title: '',
      formInputs: [
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: '',
      submitAction: null,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    edit: {
      title: '',
      formInputs: [
      ],
      hasRanges: false,
      rangesInputs: [],
      submitBtn: '',
      submitAction: null,
      isFileUpload: false,
      hasSelects: false,
      selects: [],
    },
    modalType: 'simple',
  },
};