import { deleteAlarm, getAlarms, updateAlarmHistory } from "api/alarm";
import { useEffect, useMemo, useState } from "react";
import useApp from "hooks/useApp";
import Debounce from "utilities/Debounce";
import PageNotFound from "pages/PageNotFound";

export default function Alarms() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const [chart, setChart] = useState({
    show: false,
    value: null,
  });

  const [Editable, setEditable] = useState(false);

  const { role } = useApp();

  useEffect(() => {
    if (
      role?.toLowerCase() == "admin" ||
      role?.toLowerCase() == "manager" ||
      role?.toLowerCase() == "engineer"
    ) {
      setEditable(true);
    }
  }, [role]);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAlarms();
    if (response && response.status === 200) {
      setData(
        response.data.sort((a: any, b: any) => {
          return b.id - a.id;
        })
      );
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAlarms();
    if (response && response.status === 200) {
      setData(
        response.data.sort((a: any, b: any) => {
          return b.id - a.id;
        })
      );
    }
  };

  const acknowledge = async (data: any) => {
    const status = data.statusId === 0 ? 1 : 0;
    const response: any = await updateAlarmHistory(data.id, status);
    if (response && response.status === 200) {
      refreshData();
    }
  };

  const remove = async (id: any) => {
    const response: any = await deleteAlarm(id);
    if (response && response.status === 200) {
      refreshData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      const searchByPIname = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.tagNameIdentifier.toLowerCase() === keyword
      );
      const searchByTagsName = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
      items = [...searchByPIname, ...searchByTagsName];
    }
    return items;
  }, [data, debouncedKeyword]);

  return (
    <>
        <PageNotFound type={1}></PageNotFound>

      {/* {loading && (
        <div className="fixed inset-0 grid place-content-center bg-black/70">
          <Loader />
        </div>
      )}
      {!loading && !error && data && (
        <div className="pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full">
          <div className="flex w-full items-center justify-between rounded-md">
            <div className="flex gap-5">
              <div>
                <input
                  onChange={(e: any) => setKeyword(e.target.value)}
                  className="input"
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
          <div className="gradient p-0.5 rounded-md mt-3">
            <div className="p-3 rounded-md bg-black h-full max-h-[700px] overflow-scroll">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="text-green">
                    <th className="text-left">Tag Name</th>
                    <th className="text-left">Tag Description</th>
                    <th className="text-left">Tag Description</th>
                    <th className="text-left">Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((alarm: any) => (
                      <tr
                        key={alarm.id}
                        className={classNames(
                          alarm.statusId === 0
                            ? "bg-lightRed"
                            : "bg-transparent",
                          "border-b border-b-lightGray hover:bg-dark duration-300"
                        )}
                      >
                        <td>{alarm.tagNameIdentifier}</td>
                        <td>{alarm.tagName}</td>
                        <td>
                          {dayjs(alarm.createdOn).format("MM/DD/YYYY")},{" "}
                          {dayjs(alarm.createdOn).format("HH:mm:ss A")}
                        </td>
                        <td>{alarm.statusId === 0 ? "New" : "Acknowledged"}</td>
                        <td>
                          <div className="flex gap-5 items-center justify-center">
                            <div>
                              <button
                                className="btn"
                                onClick={() => acknowledge(alarm)}
                              >
                                {alarm.statusId === 0 ? "Acknowledge" : "Open"}
                              </button>
                            </div>
                            <div
                              className={classNames(
                                Editable
                                  ? "hover:text-red"
                                  : "hover:text-white",
                                "flex gap-2 items-center cursor-pointer"
                              )}
                            >
                              <button
                                className="btn-sec flex gap-1 items-center"
                                style={{
                                  cursor: Editable ? "" : "not-allowed",
                                }}
                                onClick={() =>
                                  Editable ? remove(alarm.id) : null
                                }
                              >
                                <FiTrash2 />
                                <span>Delete</span>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
