import { Popover, PopoverTrigger, PopoverContent } from "@radix-ui/react-popover";
import { createLabel, deleteLabel, getLabel, updateLabel } from "api/unity";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "cmdk";
import { SmallLoader } from "components/shared/Loader";
import { useState, useEffect } from "react";
import { FiX, FiChevronDown } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import TextOverflow from "utilities/TextOverflow";

const Label: any = ({
  LabelId,
  LabelDetails,
  closeHandler,
  updateLabels,
  pauseUnity,
  resumeUnity,
  allLabels
}: any) => {
  const [loading, setLoading] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [values, setValues] = useState<any>();
  const [openSelectLabel, setopenSelectLabel] = useState(false);
  const [selectLabelValue, setselectLabelValue] = useState("");
  const [LabelData, setLabelData] = useState<any>(null);
  const editMode = Boolean(LabelId);
  const [errorMessage, setErrorMessage] = useState('');

  const [isParent, setisParent] = useState<any>(false);
  const [ParentLabelOptions, setParentLabelOptions] = useState<any>(null);

  const [formData, setFormData] = useState({
    prefabId: null,
    prefabParentId: null,
    name: '',
    desc: '',
    x: null,
    y: null,
    z: null,
    id: null
  });

  useEffect(() => {
    setLoading(true);
    const nullLabel: any = {
      prefabId: null,
      prefabParentId: null,
      name: 'None',
      desc: 'None',
      x: null,
      y: null,
      z: null,
      id: null
    };
    if (allLabels && allLabels.length > 0) {
      if (LabelData) {
        const nullParentLabels = allLabels.filter((label: any) => label.prefabParentId === null && label.prefabId != LabelData.prefabId);
        const id = LabelData.prefabId;
        const children = allLabels.filter((label: any) => label.prefabParentId === id);
        if (children.length > 0) {
          setisParent(true);
        }
        const options: any = isParent ? [nullLabel] : [nullLabel, ...nullParentLabels];
        setParentLabelOptions(options);
        setLoading(false);
      }
      else {
        const nullParentLabels = allLabels.filter((label: any) => label.prefabParentId === null);
        const options: any = [nullLabel, ...nullParentLabels];
        setParentLabelOptions(options);
        setLoading(false);
      }
    }
    setLoading(false);
  }, [LabelData]);

  useEffect(() => {
    pauseUnity();
    return () => resumeUnity();
  }, []);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (editMode) {
        if (LabelId) {
          const Label = await getLabel(LabelId);
          setLabelData(Label.data);
          setValues({
            ...values,
            id: Label.data.id,
            x: Label.data.x,
            y: Label.data.y,
            z: Label.data.z,
            prefabParentId: Label.data.prefabParentId,
            prefabId: Label.data.prefabId,
          });
          setFormData((prevData) => ({
            ...prevData,
            name: Label.data.name,
            desc: Label.data.desc,
            prefabId: Label.data.prefabId,
            id: Label.data.id,
            x: Label.data.x,
            y: Label.data.y,
            z: Label.data.z,
          }));
          if (Label.data.prefabParentId) {
            setFormData((prevData) => ({
              ...prevData,
              prefabParentId: Label.data.prefabParentId,
            }));
            setselectLabelValue(
              allLabels.filter(
                (label: any) => label.prefabId === Label.data.prefabParentId
              )[0].name
            );
          }
        }
      }
    })();
    setLoading(false);
  }, [LabelId]);

  const LabelHandler = async (e: any) => {
    setSubmittingForm(true);
    e.preventDefault();
    if (editMode) {
      await updateLabel({
        name: formData.name,
        desc: formData.desc,
        x: values.x,
        y: values.y,
        z: values.z,
        id: values.id,
        prefabParentId: formData.prefabParentId,
        prefabId: values.prefabId,
      });
      updateLabels();
      setSubmittingForm(false);
    } else {
      await createLabel({
        name: formData.name,
        desc: formData.desc,
        x: LabelDetails.x,
        y: LabelDetails.y,
        z: LabelDetails.z,
        prefabParentId: formData.prefabParentId || null,
      });
      updateLabels();
      setSubmittingForm(false);
    }
    closeHandler();
  };

  const deleteHandler = async () => {
    setSubmittingForm(true);
    if (LabelId) {
      const response = await deleteLabel(LabelId);
      if (response.status === 200) {
        setValues(null);
        updateLabels();
        closeHandler();
      }
      else {
        setErrorMessage(response.data.Validations[0]);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-10 w-full h-screen">
      <div
        className={classNames("h-full",
          "absolute z-20 inset-0 w-full bg-black/70"
        )}
      />

      <div className={classNames("top-1/2", "w-full h-auto gradient p-0.5 rounded-md z-30 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px]")}>
        <div className="bg-dark p-3 w-full h-full rounded-md">
          {loading && (
            <div className="w-full h-[300px] grid place-content-center">
              <SmallLoader />
            </div>
          )}
          {!loading && (
            <>
              {/* Header */}
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-green">
                  Label Configuration
                </h1>
                <div
                  className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                  onClick={closeHandler}
                >
                  <FiX size={20} />
                </div>
              </div>
              {/* Content */}
              <div className="mt-5">
                <form className="mt-3" onSubmit={LabelHandler}>
                  <div className="mt-3">
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      name="name"
                      placeholder="Name"
                      type="text"
                      required={true}
                      className="input mt-1"
                      value={formData.name}
                      onChange={(e: any) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="desc">Description</label>
                    <textarea
                      rows={5}
                      id="desc"
                      name="desc"
                      placeholder="Description"
                      className="input mt-1 max-h-[35vh]"
                      value={formData.desc}
                      onChange={(e: any) => setFormData((prevData) => ({ ...prevData, desc: e.target.value }))}
                    />
                  </div>

                  <div className="flex items-center gap-3 mt-3">
                    {/* parent */}
                    <div className="w-1/2">
                      <div className="mb-2">Parent Label</div>
                      <Popover
                        open={openSelectLabel}
                        onOpenChange={setopenSelectLabel}
                      >
                        <PopoverTrigger asChild>
                          <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                            {selectLabelValue && selectLabelValue !== ""
                              ? TextOverflow(selectLabelValue, 27)
                              : "Select Parent Label"}
                            <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0 border-slate bg-black command">
                          <Command className="bg-dark border-slate">
                            <CommandEmpty>No Labels found.</CommandEmpty>
                            {ParentLabelOptions && (
                              <CommandGroup className="bg-dark border-slate h-full overflow-hidden overflow-y-scroll">
                                <div>
                                  {ParentLabelOptions &&
                                    ParentLabelOptions.map((item: any) => (
                                      <CommandItem
                                        className="pl-3"
                                        key={item.id}
                                        onSelect={(currentValue) => {
                                          setFormData((prevData) => ({ ...prevData, prefabParentId: item.prefabId }));
                                          setselectLabelValue(item.name);
                                          setopenSelectLabel(false);
                                        }}
                                      >
                                        {item.name}
                                      </CommandItem>
                                    ))}
                                </div>
                              </CommandGroup>
                            )}
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>

                  {errorMessage.length > 0 && <span className="text-red">{errorMessage}</span>}

                  <div className="flex flex-row-reverse mt-5 gap-5">
                    <input
                      name="create"
                      id="create"
                      key="create"
                      value={editMode ? "Update" : "Create"}
                      type="submit"
                      className="btn btn-fill btn-primary"
                      disabled={submittingForm}
                    />
                    {editMode && (
                      <input
                        name="delete"
                        id="delete"
                        key="delete"
                        value="Delete"
                        type="button"
                        onClick={deleteHandler}
                        className="btn"
                      />
                    )}
                    {submittingForm && !errorMessage.length && <SmallLoader />}
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Label;
