import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from "react-icons/fi";
import useScreen from "hooks/useScreen";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import "moment-timezone";
import { useState, useEffect } from "react";
import classNames from "utilities/ClassNames";
import { MdInfoOutline } from "react-icons/md";
import useApp from "hooks/useApp";

export default function Info() {
  const { dateTime, setDateTime } = useApp();
  const { isScaled } = useScreen();

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<any>(new Date());
  const [selectedTime, setSelectedTime] = useState<any>(null);

  useEffect(() => {
    if (selectedTime && date) {
      const newDate = date.setHours(selectedTime.h, selectedTime.m, 0);
      setDate(new Date(newDate));
      setDateTime(new Date(newDate));
    }
  }, [selectedTime]);
  return (
    <div className="relative mr-5">
      {open && (
        <div
          className="fixed z-10 inset-0 w-full h-full bg-transparent"
          onClick={() => setOpen(false)}
        />
      )}
      <div className="relative z-20">
        <Popover open={open}>
          <PopoverTrigger
            asChild
            className="text-white cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <div className="cursor-pointer hover:text-green">
              <MdInfoOutline size={40} className="lg:w-[80%] xl:w-[85%] 2xl:w-full" />
            </div>
          </PopoverTrigger>
          <PopoverContent
            className={classNames(
              isScaled ? "text-xl" : "text-base",
              "w-auto bg-black mt-5 p-0"
            )}
            align="end"
          >
            <div className="lg:w-[450px] lg:h-[400px] xl-2xl:w-[500px] xl-2xl:h-[450px] 2xl:w-[550px] 2xl:h-[500px] overflow-hidden overflow-y-scroll pl-3 pt-3 pb-3">
              <h1 className="text-green font-bold lg:text-lg 2xl:text-2xl">Key Guide</h1>
              <div className="mt-5">
                {/* titles */}
                <div className="flex">
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl underline">Action</div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl underline">Keyboard/Mouse</div>
                </div>
                {/* one */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-24 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Horizontal Movement</p>
                    </div>
                    <div className="flex gap-3 ">
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        a
                      </div>
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        d
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl px-1">
                    <p>a - left, d - right</p>
                  </div>
                </div>
                {/* two */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-24"></div>
                    <div className="flex gap-3">
                      <div className="lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        <FiChevronLeft />
                      </div>
                      <div className="lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        <FiChevronRight />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-[13px] xl-2xl:text-[17px] 2xl:text-[18px]">
                    <p>left-arrow - left, right-arrow - right</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* three */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-24 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Vertical Movement</p>
                    </div>
                    <div className="flex gap-3">
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        w
                      </div>
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        d
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>w - forward, s - backward</p>
                  </div>
                </div>
                {/* four */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-24"></div>
                    <div className="flex gap-3">
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        <FiChevronUp />
                      </div>
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        <FiChevronDown />
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>up-arrow - forward, down-arrow - backward</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* five */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-28 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Fly</p>
                    </div>
                    <div className="flex gap-3">
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        f
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>f - to fly</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* six */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-28 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Lock Flying</p>
                    </div>
                    <div className="flex gap-3">
                      <div className="h-7 w-7 lg:w-6 lg:h-6 2xl:w-7 2xl:h-7 grid place-content-center bg-green text-white rounded-md">
                        l
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>l - to lock the flying height</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* seven */}
                <div className="flex mt-5">
                  <div className="flex flex-1 gap-5">
                    <div className="w-40 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Medium Speed</p>
                    </div>
                    <div className="flex w-full gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Movement + </p>
                      <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                        Left Shift
                      </div>
                    </div>
                  </div>
                  <div className="lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>Movement key + Left Shift</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* eight */}
                <div className="flex mt-5">
                  <div className="flex flex-1 gap-5">
                    <div className="w-40 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>High Speed</p>
                    </div>
                    <div className="flex w-full gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Movement + </p>
                      <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                        Right Shift
                      </div>
                    </div>
                  </div>
                  <div className="lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                    <p>Movement key + Right Shift</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* nine */}
                <div className="flex mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-40 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Tags</p>
                    </div>
                    <div className="flex w-full gap-1">
                      <div className="flex items-center gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                        <p>Mouse</p>
                        <FiChevronRight />
                      </div>
                      <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                        Left Click
                      </div>
                    </div>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* ten */}
                <div className="flex flex-1 mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-36 lg:text-sm 2xl:text-[1rem]">
                      <p>Expression LookUp</p>
                    </div>
                    <div className="flex gap-1">
                      <div className="flex items-center gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                        <p>Mouse</p>
                        <FiChevronRight />
                      </div>
                      <div className="lg:text-xs 2xl:text-md px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                        Right Click
                      </div>
                    </div>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* eleven */}
                <div className="flex flex-1 mt-5">
                  <div className="flex-1 flex gap-5">
                    <div className="w-28 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Release cursor</p>
                    </div>
                    <div className="flex gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <div className="px-1 h-7 w-20 grid place-content-center bg-green text-white rounded-md">
                        Esc
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 lg:text-[13px] px-1 xl-2xl:text-lg 2xl:text-xl">
                    <p>Escape Key - to release cursor</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />

                {/* seven */}
                <div className="flex flex-1 mt-5">
                  <div className="flex flex-1 gap-5">
                    <div className="w-32 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                      <p>Lock cursor</p>
                    </div>
                    <div className="flex gap-1">
                      <div className="flex items-center gap-1 lg:text-sm xl-2xl:text-lg 2xl:text-xl">
                        <p>Mouse</p>
                        <FiChevronRight />
                      </div>
                      <div className="lg:text-xs 2xl:text-md px-1 h-8 mt-2 grid place-content-center bg-green text-white rounded-md">
                        Double Click
                      </div>
                    </div>
                  </div>
                  <div className="flex p-1 lg:text-sm xl-2xl:text-md 2xl:text-lg">
                    <p>Click anywhere in the 3d environment</p>
                  </div>
                </div>
                {/* divider */}
                <div className="w-full h-[1px] bg-lightGray mt-5" />
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
