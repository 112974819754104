import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import moment from "moment";
import StartEndDate from "./StartEndDate";
import PointCloudTable from "./PointCloudTable";
import useUnity from "hooks/UseUnity";

const ProjectDate = () => {
  const {
    projectEnddate,
    projectStartdate,
  } = useUnity();

  const marks = Array.from({ length: 101 }).map((_, index) => {
    const startDate = moment(projectStartdate);
    const endDate = moment(projectEnddate);
    const diffDays = endDate.diff(startDate, "days");
    const currentDate = startDate
      .clone()
      .add(Math.round((index / 100) * diffDays), "days");

    return {
      date: currentDate.format("DD/MM/YYYY"),
      value: index,
      label: null,
    };
  });
  return (
    <div className="flex flex-col gap-y-24 h-full p-4">
      <StartEndDate
        marks={marks}
      ></StartEndDate>
      <BorderGradient className="h-full">
        <PointCloudTable></PointCloudTable>
      </BorderGradient>
    </div>
  );
};

export default ProjectDate;
