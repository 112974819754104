import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useScreen from "hooks/useScreen";
import useData from "hooks/useData";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import TextOverflow from "utilities/TextOverflow";

export default function ComboBox({ selectedValue, hasFilters, setDefaultUom, filters, select, clear }: any) {
  const { isScaled } = useScreen();
  const { expressions, tags } = useData();
  const [data, setData] = useState<any>(tags);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [typeSelected, setTypeSelected] = useState("tags");
  const [filteredData, setFilteredData] = useState(data);

  const [label, setLabel] = useState("");

  useEffect(() => {
    if (selectedValue) {
      setDefaultUom((prev: any) => [...prev, selectedValue.uom]);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue?.tagNameIdentifier) {
      setLabel(selectedValue?.tagNameIdentifier);
    } else {
      if (selectedValue?.unitName !== "" && selectedValue?.unitName) {
        setLabel(`${selectedValue?.name} - ${selectedValue?.unitName}`);
      } else if (
        selectedValue?.equipmentName !== "" &&
        selectedValue?.equipmentName
      ) {
        setLabel(`${selectedValue?.name} - ${selectedValue?.equipmentName}`);
      } else {
        setLabel(selectedValue?.name);
      }
    }
  }, []);

  useEffect(() => {
    if (hasFilters) {
      const newFilteredData = data.filter((item: any) => filters.includes(item.uom));
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(data);
    }
  }, [data, filters]);

  const switchData = () => {
    if (typeSelected === "tags") {
      setTypeSelected("expressions");
      setData(expressions);
    } else {
      setTypeSelected("tags");
      setData(tags);
    }
  };

  return (
    <div className="w-full relative">
      {selectedValue && (
        <div
          onClick={() => {
            clear();
            setLabel("");
          }}
          className={classNames(
            isScaled ? "top-2" : "top-2.5",
            "absolute right-3 bg-red rounded-full p-0.5 cursor-pointer flex items-center h-center"
          )}
        >
          <FiX
            size={isScaled ? 10 : 10}
            className={classNames(isScaled ? "" : "")}
          />
        </div>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div
            className={classNames(
              isScaled ? "text-xs h-7" : "text-sm h-9",
              "w-full whitespace-nowrap px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black"
            )}
          >
            {label && label !== "" ? TextOverflow(label, 27) : "Select Value"}
            {/* <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" /> */}
          </div>
        </PopoverTrigger>
        <PopoverContent className="w-[240px] p-0 border-slate bg-black command">
          <Command className="bg-dark border-slate">
            <CommandInput
              placeholder="Search"
              className={classNames(
                isScaled ? "text-xl" : "",
                "bg-dark border-b-slate"
              )}
            />
            <CommandEmpty className={classNames(isScaled ? "text-2xl" : "")}>
              No <span className="capitalize">{typeSelected}</span> found.
            </CommandEmpty>
            {filteredData && filteredData.length > 0 && (
              <CommandGroup className="bg-dark border-slate h-[230px] overflow-hidden overflow-y-scroll">
                <div
                  className="cursor-pointer text-xs whitespace-nowrap py-2 pl-3"
                  onClick={() =>
                    setTypeSelected(
                      typeSelected === "tags" ? "expressions" : "tags"
                    )
                  }
                >
                  <div
                    className={classNames(
                      isScaled ? "text-lg" : "",
                      "bg-slate rounded-md w-full py-2 text-center"
                    )}
                    onClick={switchData}
                  >
                    Switch to {typeSelected === "tags" ? "Expressions" : "Tags"}
                  </div>
                </div>
                <div>
                  {filteredData &&
                    filteredData.map((item: any) => (
                      <CommandItem
                        className="pl-3"
                        key={item.id}
                        onSelect={(currentValue) => {
                          select(item);
                          setLabel(
                            typeSelected === "tags"
                              ? item.tagNameIdentifier
                              : `${item.name}${item.unitName !== "" && item.unitName !== null
                                ? ` - ${item.unitName}`
                                : item.equipmentName !== "" &&
                                  item.equipmentName !== null
                                  ? ` - ${item.equipmentName}`
                                  : ""
                              }`
                          );
                          setValue(currentValue === value ? "" : currentValue);
                          setOpen(false);
                        }}
                      >
                        {typeSelected === "expressions" && (
                          <span
                            className={classNames(isScaled ? "text-lg" : "")}
                          >
                            {TextOverflow(
                              `${item.name}${item.unitName !== "" && item.unitName !== null
                                ? ` - ${item.unitName}`
                                : item.equipmentName !== "" &&
                                  item.equipmentName !== null
                                  ? ` - ${item.equipmentName}`
                                  : ""
                              }`,
                              27
                            )}
                          </span>
                        )}
                        {typeSelected === "tags" && (
                          <span
                            className={classNames(isScaled ? "text-lg" : "")}
                          >
                            {TextOverflow(item.tagNameIdentifier, 27)}
                          </span>
                        )}
                      </CommandItem>
                    ))}
                </div>
              </CommandGroup>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
